import React, { useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import I18n, { translate } from '@utils/i18n';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import weekData from 'cldr-core/supplemental/weekData.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import { DateTime, Duration } from 'luxon';
import { checkAvailableTechniciansPath } from 'routes';
import api from '@utils/axios';
import { CalendarContext } from '../../CalendarContext';
import esMessages from '../../../../locales/es.json';
import frMessages from '../../../../locales/fr.json';

load(weekData, esCaGregorian, esDateFields, frCaGregorian, frDateFields); // to i18n DateTimePicker component
loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');

const namespace = 'features.calendar.edit_modal.form';

function Form({ onCloseModal, initialFormData }) {
  const {
    inspectionStatusesForSelect,
    visitStatusesForSelect,
    techniciansForSelect,
    actions: { onEventSave }
  } = useContext(CalendarContext);

  const [formData, setFormData] = useState(initialFormData);
  const [checkAvailableNotification, setCheckAvailableNotification] = useState({ message: '', data: null });
  const duration = useMemo(
    () => Duration.fromObject({ minutes: formData.durationMins }).shiftTo('days', 'hours', 'minutes'),
    [formData.durationMins]
  );

  useEffect(() => {
    setCheckAvailableNotification({ message: '', data: null });
    if (!formData.technicianId || formData.technicianId === 'Unassigned') return;

    const controller = new AbortController();
    api
      .get(checkAvailableTechniciansPath({ format: 'json' }), {
        signal: controller.signal,
        params: {
          default_object_name: formData.type,
          default_object_id: formData.objectId,
          technician_id: formData.technicianId,
          scheduled_date: formData.start,
          duration: formData.durationMins
        }
      })
      .catch((error) =>
        setCheckAvailableNotification({
          message: error.response?.data?.message,
          data: error.response?.data?.data
        })
      );
    return () => controller.abort();
  }, [formData.start, formData.durationMins, formData.technicianId]);

  const handleCommonChange = (field) => {
    return (e) => {
      setFormData((old) => ({ ...old, [field]: e.target.value }));
    };
  };

  const handleDdChange = (field) => {
    return (e) => {
      setFormData((old) => ({ ...old, [field]: e.target.value.value }));
    };
  };

  const statusOptions = () => {
    let options;

    if (formData.type === 'Visit') {
      options = visitStatusesForSelect.map((x) => (
        <option key={x.value} value={x.value}>
          {x.label}
        </option>
      ));
    } else {
      options = inspectionStatusesForSelect.map((x) => (
        <option key={x.value} value={x.value}>
          {x.label}
        </option>
      ));
    }
    return options;
  };

  const technicianOptions = () => {
    return techniciansForSelect.map((technician) => (
      <option key={technician.value} value={technician.value}>
        {technician.label}
      </option>
    ));
  };

  const handleDurationChange = (newDuration) => {
    setFormData((old) => ({
      ...old,
      durationMins: Duration.fromObject({
        days: duration.days,
        hours: duration.hours,
        minutes: duration.minutes,
        ...newDuration
      }).as('minutes')
    }));
  };

  const handleDateChange = (e) => {
    setFormData((old) => ({
      ...old,
      start: DateTime.fromJSDate(e.value).minus({ minutes: timezoneGapMinutes }).toString()
    }));
  };

  const onSave = () => {
    onEventSave(formData);
    onCloseModal();
  };

  const inspectionStatusValue = () => {
    if (formData.status === '') return null;

    return inspectionStatusesForSelect.find((elem) => elem.value === formData.status);
  };

  const statusValueRender = (element, value) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className="qmb-avatar--24--status-icon"
          style={{ display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <i className={`fa-lg ${value.icon}`} style={{ color: value.color }} />
        </span>
        <div style={{ marginLeft: '0.8rem' }}>{element.props.children}</div>
      </div>
    );
  };

  const statusItemRender = (element, itemProps) => {
    return React.cloneElement(
      element,
      element.props,
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className="qmb-avatar--24--status-icon"
          style={{ display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <i className={`fa-lg ${itemProps.dataItem.icon}`} style={{ color: itemProps.dataItem.color }} />
        </span>
        <div style={{ marginLeft: '0.8rem' }}>{element.props.children}</div>
      </div>
    );
  };

  // Since DatePicker & TimePicker do not support custom timezones the time is artificially changed in form.
  // Here difference between browser's and backend's timezones is added to event's start time
  const timezoneGapMinutes = useMemo(() => formData.utcOffset - DateTime.fromISO(formData.start).offset, []);

  const monkeyPatchedStartTime = useMemo(() => {
    return DateTime.fromISO(formData.start).plus({ minutes: timezoneGapMinutes }).toJSDate();
  }, [formData.start]);

  return (
    <div className="dialog__section">
      <div className="dialog__header--section">
        <h2 className="dialog__title">{translate('scheduling_details', { namespace })}</h2>
      </div>
      <fieldset className="form__set--vertical--compact">
        <div className="qmb-select--x-full form__field">
          {(formData.type === 'Inspection' || formData.type === 'InspectionVisit') && (
            <DropDownList
              name="status"
              id="status"
              textField="label"
              dataItemKey="value"
              data={inspectionStatusesForSelect}
              itemRender={statusItemRender}
              valueRender={statusValueRender}
              value={inspectionStatusValue()}
              onChange={handleDdChange('status')}
              size="large"
            />
          )}

          {formData.type === 'Visit' && (
            <select
              className="tech-select additional-tech"
              value={formData.status}
              onChange={handleCommonChange('status')}
              disabled={formData.readonly}>
              {statusOptions()}
            </select>
          )}
          <label className="qmb-label">{translate('status', { namespace })}</label>
        </div>
        <div className="qmb-select--x-full form__field">
          <select
            className="tech-select additional-tech"
            value={formData.technicianId}
            onChange={handleCommonChange('technicianId')}
            disabled={formData.readonly}>
            {technicianOptions()}
          </select>
          <label className="qmb-label">{translate('technician', { namespace })}</label>
        </div>

        <fieldset className="form__set--horizontal--compact">
          <LocalizationProvider language={I18n.locale}>
            <IntlProvider locale={I18n.locale}>
              <div className="qmb-input--text--x-half form__field">
                <DatePicker value={monkeyPatchedStartTime} onChange={handleDateChange} />
                <label className="qmb-label">{translate('scheduled_date', { namespace })}</label>
              </div>
              <div className="qmb-input--text--x-half form__field">
                <TimePicker value={monkeyPatchedStartTime} onChange={handleDateChange} />
                <label className="qmb-label">{translate('scheduled_time', { namespace })}</label>
              </div>
            </IntlProvider>
          </LocalizationProvider>
        </fieldset>

        <fieldset className="form__set--horizontal--compact">
          <div className="form__field--x-quarter">
            <span className="qmb-label" style={{ width: '100%' }}>
              {translate('duration', { namespace })}:
            </span>
          </div>
          <div className="qmb-input--number--x-quarter duration-field">
            <input
              type="number"
              id="duration_days"
              min="0"
              value={duration.days}
              onChange={(e) => handleDurationChange({ days: e.target.value || 0 })}
              disabled={formData.readonly}
            />
            <label className="qmb-label" htmlFor="duration_days">
              {translate('days', { namespace })}
            </label>
          </div>
          <div className="qmb-input--number--x-quarter duration-field">
            <input
              type="number"
              id="duration_hours"
              min="0"
              max="23"
              value={duration.hours}
              onChange={(e) => handleDurationChange({ hours: e.target.value || 0 })}
              disabled={formData.readonly}
            />
            <label className="qmb-label" htmlFor="duration_hours">
              {translate('hours', { namespace })}
            </label>
          </div>
          <div className="qmb-input--number--x-quarter duration-field">
            <input
              type="number"
              id="duration_mins"
              min="0"
              max="59"
              value={duration.minutes}
              onChange={(e) => handleDurationChange({ minutes: e.target.value || 0 })}
              disabled={formData.readonly}
            />
            <label className="qmb-label" htmlFor="duration_mins">
              {translate('minutes', { namespace })}
            </label>
          </div>
        </fieldset>
      </fieldset>

      <div className="modal__footer" style={{ marginTop: '16px' }}>
        <div style={{ height: '40px', margin: 'auto' }}>
          {checkAvailableNotification.message}
          <br />
          {checkAvailableNotification.data && (
            <a href={checkAvailableNotification.data.link} target="_blank" rel="noreferrer">
              {checkAvailableNotification.data.text_link}
            </a>
          )}
        </div>
        <button type="button" className="qmb-button" id="charge_customer_cancel_btn" onClick={onCloseModal}>
          {translate('cancel', { namespace })}
        </button>
        {!formData.readonly && (
          <button type="button" className="qmb-button--submit" onClick={onSave}>
            {translate('save', { namespace })}
          </button>
        )}
      </div>
    </div>
  );
}

Form.propTypes = {
  initialFormData: PropTypes.shape({
    details: PropTypes.object,
    status: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.string,
    start: PropTypes.string
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired
};

export default Form;
