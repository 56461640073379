import React, { useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from './ActionBar';
import {
  actions,
  deleteEvent,
  setStatus,
  status as schedulerStatuses,
  stopRepositioningEvent
} from '../Redux/schedulerSlice';
import { startDragging, types } from '../Redux/draggingSlice';

function Header() {
  const dispatch = useDispatch();
  const schedulerStatus = useSelector((state) => state.scheduler.status);
  const activeEvent = useSelector((state) => state.scheduler.activeEvent);
  const currentAction = useSelector((state) => state.scheduler.action);

  const HandleMouseEnter = (e) => {
    if (schedulerStatus === schedulerStatuses.REPOSITIONING && activeEvent) {
      if (currentAction === actions.SAMPLING_VISIT)
        dispatch(startDragging({ data: { ...activeEvent }, x: e.clientX, y: e.clientY, type: types.VISIT }));
      else dispatch(startDragging({ data: { ...activeEvent }, x: e.clientX, y: e.clientY, type: types.EVENT }));

      dispatch(stopRepositioningEvent({ key: activeEvent.key, save: false }));
      dispatch(deleteEvent(activeEvent.key));
      dispatch(setStatus(schedulerStatuses.DRAGGING));
    }
  };

  return (
    <div className="schedule__header" onMouseEnter={HandleMouseEnter}>
      <div className="schedule__top">
        <ActionBar />
        <TimeBar />
      </div>
      <hr className="workspace__split" />
    </div>
  );
}

function TimeBar() {
  const barRef = useRef(null);

  const times = [];
  for (let i = 0; i < 24; i += 1) {
    times.push(
      <div className="time__block" key={`header-time-${i}`}>
        <span className="time__label">{moment().hour(i).minute(0).format('h A')}</span>
      </div>
    );
  }

  const HandleMouseDown = () => {
    barRef.current.classList.add('--active');
    document.addEventListener('mousemove', HandleMouseMove);
    document.addEventListener('mouseup', HandleMouseUp, { once: true });
  };

  const HandleMouseMove = (e) => {
    document.getElementById('scheduler').scrollLeft += -e.movementX * 1.4;
  };

  const HandleMouseUp = () => {
    barRef.current.classList.remove('--active');

    document.removeEventListener('mousemove', HandleMouseMove);
    document.removeEventListener('mouseup', HandleMouseUp);
  };

  return (
    <div
      role="presentation"
      ref={barRef}
      id="scheduler-time-header"
      className="schedule__times"
      onMouseDown={HandleMouseDown}
      onMouseUp={HandleMouseUp}>
      {times}
    </div>
  );
}

export default Header;
