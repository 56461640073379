import { useCallback, useState } from 'react';
const useApiQuery = (initialState) => {
    const [apiParams, setApiParms] = useState(initialState);
    const nextPage = useCallback(() => {
        if (apiParams.select !== 'all') {
            const { page, size } = apiParams.select;
            setApiParms((prevState) => (Object.assign(Object.assign({}, prevState), { select: {
                    page: page + 1,
                    size
                } })));
        }
    }, [apiParams]);
    const prevPage = useCallback(() => {
        if (apiParams.select !== 'all') {
            const { page, size } = apiParams.select;
            setApiParms((prevState) => (Object.assign(Object.assign({}, prevState), { select: {
                    page: page - 1,
                    size
                } })));
        }
    }, [apiParams]);
    return { apiParams, nextPage, prevPage };
};
export default useApiQuery;
