import React, { useEffect, useReducer, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import { Upload, ExternalDropZone } from '@progress/kendo-react-upload';
import I18n, { translate } from '@utils/i18n';
import { photosPath } from 'routes';
import { DateTime } from 'luxon';
import toast, { Toaster } from 'react-hot-toast';
import { actionTypes, reducer } from './reducer';
import NavigationPanel from './nested/NavigationPanel';
import MonthGroup from './nested/MonthGroup';
import { Loader, LoaderSizes } from '../../components/Loader/Loader';
import './PhotoGallery.scss';

const namespace = 'features.photo_gallery.index';

function PhotoGallery({ buildingId, inspectionId, workOrderId }) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [uploading, setUploading] = useState(false);
  const photosUploading = useRef(0);
  const [visibleElements, setVisibleElements] = useState([]);
  const uploadRef = useRef();
  const [state, dispatch] = useReducer(reducer, {
    photos: {},
    expanded: {},
    photosCount: {},
    focused: null
  });

  const pageDetails = useMemo(() => {
    if (inspectionId)
      return {
        type: 'inspection',
        id: inspectionId
      };
    if (workOrderId)
      return {
        type: 'work_order',
        id: workOrderId
      };
    return {
      type: 'building',
      id: buildingId
    };
  }, []);

  const currentYearMonth = useMemo(() => DateTime.now().toFormat('yyyyLL'), []);

  const authToken = useMemo(() => document.querySelector('meta[name="csrf-token"]').getAttribute('content'), []);

  const monthGroupsRef = useRef({});

  useEffect(() => {
    if (buildingId) return;

    const uploadDropZone = document.querySelector('#uploadDropZone .k-dropzone-inner');
    const listener = () => {
      document.getElementById('upload-select-button').click();
    };
    uploadDropZone.addEventListener('click', listener);
    return () => {
      uploadDropZone.removeEventListener('click', listener);
    };
  }, []);

  useEffect(() => {
    api
      .get(
        photosPath({
          building_id: buildingId,
          inspection_id: inspectionId,
          work_order_id: workOrderId,
          format: 'json'
        })
      )
      .then(({ data }) => {
        dispatch({ type: actionTypes.DATA_LOADED, data });
        setInitialLoad(false);
      })
      .catch(() => {
        dispatch({ type: actionTypes.DATA_LOADED, data: [] });
        setInitialLoad(false);
        toast.error(translate('load_photo_error', { namespace }), {
          position: 'bottom-right',
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  const onCollapseMonthGroup = (yearMonth) => {
    dispatch({ type: actionTypes.MONTH_COLLAPSED, yearMonth });
  };

  const onExpandMonthGroup = (yearMonth) => {
    dispatch({ type: actionTypes.MONTH_FOCUSED, focused: yearMonth });

    if (!state.expanded[yearMonth] && state.photos[yearMonth] === undefined) {
      api
        .get(
          photosPath({
            building_id: buildingId,
            inspection_id: inspectionId,
            work_order_id: workOrderId,
            year_month: yearMonth,
            format: 'json'
          })
        )
        .then(({ data }) => {
          dispatch({ type: actionTypes.DATA_LOADED, data });
        })
        .catch(() => {
          dispatch({ type: actionTypes.DATA_LOADED, data: { photos: { [yearMonth]: [], photosCount: null } } });
          toast.error(translate('load_photo_error', { namespace }), {
            position: 'bottom-right',
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }

    dispatch({ type: actionTypes.MONTH_EXPANDED, yearMonth });
  };

  useEffect(() => {
    if (!state.focused || !monthGroupsRef.current[state.focused]) return;
    monthGroupsRef.current[state.focused].scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [state.focused]);

  const monthsArray = useMemo(() => Object.entries(state.photosCount).reverse(), [state.photosCount]);

  const onUploadFinished = (event) => {
    dispatch({ type: actionTypes.PHOTO_ADDED, yearMonth: currentYearMonth, newPhoto: event.response.response });
    photosUploading.current -= 1;
    if (photosUploading.current === 0) setUploading(false);
  };

  const onBeforeUpload = () => {
    setUploading(true);
    photosUploading.current += 1;
  };

  const onChangeVisibility = ({ yearMonth, isVisible }) => {
    const sortFunction = (a, b) => {
      const indexA = monthsArray.findIndex(([id]) => id === a);
      const indexB = monthsArray.findIndex(([id]) => id === b);
      if (indexA === indexB) return 0;
      if (indexA < indexB) return -1;
      return 1;
    };
    if (isVisible) {
      setVisibleElements((oldElements) => {
        if (oldElements.includes(yearMonth)) return oldElements;
        return [...oldElements, yearMonth].sort(sortFunction);
      });
    } else {
      setVisibleElements((oldElements) => {
        return oldElements.filter((element) => element !== yearMonth).sort(sortFunction);
      });
    }
  };

  const onValidationError = (e) => {
    if (e.affectedFiles.some((file) => file.validationErrors?.length)) {
      toast.error(translate('upload_photo_error', { namespace }), {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  return (
    <div className="photo-gallery-container">
      <Toaster position="bottom-right" />
      <div className="" style={{ alignItems: 'stretch', display: 'flex', width: '100%' }}>
        <div className="tab__major">
          {!buildingId && (
            <>
              <ExternalDropZone
                customHint={<UploadCustomHint />}
                customNote={<UploadCustomNote />}
                id="uploadDropZone"
                uploadRef={uploadRef}
                style={{ marginBottom: '2.4rem', marginRight: '2.4rem', borderStyle: 'dashed' }}
              />
              <Upload
                id="upload-select-button"
                ref={uploadRef}
                batch={false}
                multiple
                disabled={uploading}
                restrictions={{ allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'] }}
                showFileList={false}
                withCredentials
                onStatusChange={onUploadFinished}
                onAdd={onValidationError}
                saveField="image"
                saveUrl={photosPath({
                  inspection_id: inspectionId,
                  work_order_id: workOrderId,
                  authenticity_token: authToken,
                  format: 'json',
                  _options: true
                })}
                onBeforeUpload={onBeforeUpload}
              />
              {uploading && (
                <div className="loaderContainer">
                  <Loader size={LoaderSizes.large} />
                </div>
              )}
            </>
          )}
          {initialLoad && (
            <div className="qmb-loading--96" data-title={I18n.t('generic.loading')}>
              <svg role="img">
                <use href="/map.svg#load-spinner" />
              </svg>
            </div>
          )}
          {!initialLoad && !Object.entries(state.photosCount).length && (
            <div style={{ textAlign: 'center', marginTop: '25px' }}>{translate('no_photos', { namespace })}</div>
          )}
          {!initialLoad &&
            Object.entries(state.photosCount).length > 0 &&
            monthsArray.map(([yearMonth, count]) => (
              <MonthGroup
                key={yearMonth}
                innerRef={(group) => {
                  monthGroupsRef.current[yearMonth] = group;
                }}
                onExpand={onExpandMonthGroup}
                onCollapse={onCollapseMonthGroup}
                yearMonth={yearMonth}
                expanded={state.expanded[yearMonth]}
                count={count}
                photos={state.photos[yearMonth]}
                dispatch={dispatch}
                pageDetails={pageDetails}
                onChangeVisibility={onChangeVisibility}
              />
            ))}
          <div style={{ height: 'calc(100vh - 280px)' }} />
        </div>
      </div>
      {Object.entries(state.photosCount).length > 0 && (
        <NavigationPanel state={state} onExpand={onExpandMonthGroup} focusElement={visibleElements[0]} />
      )}
    </div>
  );
}

function UploadCustomHint() {
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <i style={{ fontSize: '32px', color: '#1967D2' }} className="fa-light fa-cloud-arrow-up" />
      </div>
      <div style={{ textAlign: 'center' }}>
        <span style={{ color: '#1967D2' }}>{translate('upload_hint_1', { namespace })}</span>
        &nbsp;
        {translate('upload_hint_2', { namespace })}
        <br />
        {translate('upload_hint_3', { namespace })}
      </div>
    </div>
  );
}

function UploadCustomNote() {
  return (
    <div style={{ textAlign: 'center' }}>
      {translate('upload_note_1', { namespace })}
      <br />
      {translate('upload_note_2', { namespace })}
    </div>
  );
}

PhotoGallery.propTypes = {
  buildingId: PropTypes.number,
  inspectionId: PropTypes.number,
  workOrderId: PropTypes.number
};

PhotoGallery.defaultProps = {
  buildingId: undefined,
  inspectionId: undefined,
  workOrderId: undefined
};

export default PhotoGallery;
