import React, { useEffect, useState } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import api from 'axios';
import { translate } from '@utils/i18n';
import { apiInternalInspectionStatusesPath, apiInternalServiceVisitStatusesPath } from '../../../routes';
const namespace = 'components.visit_status_dropdown';
function VisitStatusDropdown(params) {
    const [statusList, setStatusList] = useState();
    useEffect(() => {
        let url;
        switch (params.type) {
            case "Visit" /* VisitType.SERVICE_VISIT */:
                url = apiInternalServiceVisitStatusesPath();
                break;
            case "InspectionVisit" /* VisitType.INSPECTION_VISIT */:
            case "Inspection" /* VisitType.INSPECTION_PRIMARY_VISIT */:
                url = apiInternalInspectionStatusesPath();
        }
        api
            .get(url)
            .then((response) => {
            const result = response.data.visit_statuses || response.data.inspection_statuses;
            setStatusList(result);
            params.onLoad();
        })
            .catch((error) => {
            setStatusList(undefined);
            console.error(error);
        });
    }, []);
    const statusValueRender = (element, value) => {
        return (React.createElement("div", { style: { display: 'flex', alignItems: 'center' } }, React.createElement("span", { className: "qmb-avatar--24--status-icon", style: { display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' } }, React.createElement("i", { className: `fa-lg ${value.icon}`, style: { color: value.color } })), React.createElement("div", { style: { marginLeft: '0.8rem' } }, element.props.children)));
    };
    const statusItemRender = (element, itemProps) => {
        return React.cloneElement(element, element.props, React.createElement("div", { style: { display: 'flex', alignItems: 'center' } }, React.createElement("span", { className: "qmb-avatar--24--status-icon", style: { display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' } }, React.createElement("i", { className: `fa-lg ${itemProps.dataItem.icon}`, style: { color: itemProps.dataItem.color } })), React.createElement("div", { style: { marginLeft: '0.8rem' } }, element.props.children)));
    };
    return (React.createElement("div", { className: "qmb-select--x-full form__field" }, React.createElement(DropDownList, { name: "status", id: "status", textField: "label", dataItemKey: "value", data: statusList, itemRender: statusItemRender, valueRender: statusValueRender, value: params.selectedElement, onChange: (e) => params.onChange(e.value), disabled: params.disabled, size: "large" }), React.createElement("label", { className: "qmb-label" }, translate('status', { namespace }))));
}
export default VisitStatusDropdown;
