/* global pendo */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Event from './Event';
import {
  addEvent,
  reassignEvent,
  setActiveLaneId,
  startRepositioningEvent,
  startResizingEvent,
  status as schedulerStatus,
  repositionEvent
} from '../Redux/schedulerSlice';
import { stopDragging, types, status as dragStatuses } from '../Redux/draggingSlice';
import { showEditModal } from '../Redux/editModalSlice';

function EventLane({ technician }) {
  const techId = technician.id;
  const laneId = useRef(uuid());
  const dispatch = useDispatch();
  const timeZone = useSelector((state) => state.settings.timeZone);
  const activeDay = useSelector((state) => state.scheduler.activeDay);
  const status = useSelector((state) => state.scheduler.status);
  const action = useSelector((state) => state.scheduler.action);
  const activeEvent = useSelector((state) => state.scheduler.activeEvent);
  const globalDragStatus = useSelector((state) => state.dragging.status);
  const draggingType = useSelector((state) => state.dragging.type);
  const draggingData = useSelector((state) => state.dragging.data);
  const [laneHeight, setLaneHeight] = useState(48);
  const visitPanelContent = useSelector((state) => state.map.visits);
  const lastClickTime = useSelector((state) => state.scheduler.lastClickTime);
  const events = useSelector(
    (state) =>
      state.scheduler.events.filter(
        (event) =>
          event.technicianId === techId &&
          DateTime.fromISO(event.endTime) > DateTime.fromISO(activeDay, { zone: timeZone })
      ),
    shallowEqual
  );

  useEffect(() => {
    const maxRows = Math.max(...events.map((x) => x.metadata.subdivision));
    setLaneHeight(maxRows * 24 + 4);
  }, [events]);

  const { HandleLaneMouseEnter } = LaneEventHandlers({
    techId,
    dispatch,
    activeEvent,
    status,
    draggingType,
    draggingData,
    activeDay,
    globalDragStatus,
    technician,
    laneId: laneId.current,
    timeZone
  });

  const EventEventsHandlers = EventsEventHandlers({
    dispatch,
    activeDay,
    status,
    action,
    activeEvent,
    globalDragStatus,
    draggingType,
    draggingData,
    visitPanelContent,
    lastClickTime,
    laneId: laneId.current
  });

  return (
    <div
      id={laneId.current}
      className="event__lane"
      onMouseEnter={HandleLaneMouseEnter}
      style={{ minHeight: `${laneHeight}px` }}>
      {events.map((x) => (
        <Event key={`event-${x.key}`} event={x} eventHandlers={EventEventsHandlers} />
      ))}
    </div>
  );
}

const getTimeFromMouseXPosition = (mouseEvent, laneId, activeDay) => {
  const activeLane = document.getElementById(laneId);
  const clickX = mouseEvent.clientX - activeLane.getBoundingClientRect().left;
  const percentage = (clickX / activeLane.clientWidth) * 100;
  const minutes = (percentage / 100) * 1440;
  const hours = Math.floor(minutes / 60);
  const minutesRemainder = Math.floor(minutes % 60);
  const targetTime = DateTime.fromISO(activeDay).set({ hour: hours, minute: minutesRemainder });
  return targetTime.toString();
};

const LaneEventHandlers = ({
  techId,
  dispatch,
  activeEvent,
  status,
  draggingType,
  draggingData,
  activeDay,
  globalDragStatus,
  technician,
  laneId,
  timeZone
}) => {
  const HandleLaneMouseEnter = (e) => {
    dispatch(setActiveLaneId(laneId));
    const activeLane = document.getElementById(laneId);
    activeLane.classList.add('--active');
    if (status === schedulerStatus.REPOSITIONING) {
      dispatch(reassignEvent({ eventKey: activeEvent.key, techId, save: false, technician }));
    } else if (globalDragStatus === dragStatuses.DRAGGING && draggingType === types.EVENT) {
      const newStartTime = getTimeFromMouseXPosition(e, laneId, activeDay);
      const duration = DateTime.fromISO(draggingData.metadata.prevEndTime).diff(
        DateTime.fromISO(draggingData.metadata.prevStartTime),
        'minutes'
      ).minutes;

      const newEvent = {
        ...draggingData,
        technicianId: techId,
        technician,
        startTime: newStartTime,
        endTime: DateTime.fromISO(newStartTime).plus({ minutes: duration }).toString()
      };

      dispatch(stopDragging());
      dispatch(addEvent({ data: JSON.parse(JSON.stringify(newEvent)) }));
      dispatch(startRepositioningEvent({ key: newEvent.key, minuteOffset: 0 }));
    } else if (globalDragStatus === dragStatuses.DRAGGING && draggingType === types.VISIT) {
      pendo.track('Map Scheduler', {
        EventType: 'Visit dragged to Scheduler'
      });
      const newStartTime = getTimeFromMouseXPosition(e, laneId, activeDay);
      const duration = DateTime.fromISO(draggingData.endTime).diff(
        DateTime.fromISO(draggingData.startTime),
        'minutes'
      ).minutes;

      const newEvent = {
        ...draggingData,
        technicianId: techId,
        technician,
        startTime: newStartTime,
        endTime: DateTime.fromISO(newStartTime).plus({ minutes: duration }).toString()
      };
      dispatch(stopDragging());
      dispatch(addEvent({ data: JSON.parse(JSON.stringify(newEvent)) }));
      dispatch(startRepositioningEvent({ key: newEvent.key, minuteOffset: 0, isSampling: true }));

      const targetTime = DateTime.fromISO(getTimeFromMouseXPosition(e, laneId, activeDay, timeZone)).minus({
        minutes: 0
      });
      dispatch(repositionEvent({ key: newEvent.key, targetTime: targetTime.toString() }));
    }
  };

  const HandleLaneMouseLeave = () => {
    const activeLane = document.getElementById(laneId);
    activeLane.classList.remove('--active');
  };

  return {
    HandleLaneMouseEnter,
    HandleLaneMouseLeave
  };
};

const EventsEventHandlers = ({ dispatch, activeDay, globalDragStatus, draggingType, draggingData, laneId }) => {
  const HandleEventDoubleClick = (e) => {
    e.stopPropagation();
  };

  const HandleEventRepositionMouseDown = (e, key, startTime) => {
    e.stopPropagation();
    const targetTime = getTimeFromMouseXPosition(e, laneId, activeDay);
    let minuteOffset = DateTime.fromISO(targetTime).diff(DateTime.fromISO(startTime), 'minutes').minutes;
    if (minuteOffset < 0) minuteOffset = 0;
    dispatch(setActiveLaneId(laneId));
    dispatch(startRepositioningEvent({ key, minuteOffset }));
  };

  const HandleEventResizeMouseDown = (e, key, isLeftHandle, snap) => {
    dispatch(setActiveLaneId(laneId));
    dispatch(startResizingEvent({ key, isResizingLeft: isLeftHandle, isSnapping: snap }));
  };

  const HandleMouseUp = (e, key) => {
    if (globalDragStatus === dragStatuses.DRAGGING && draggingType === types.TECHNICIAN) {
      dispatch(stopDragging());
      dispatch(reassignEvent({ eventKey: key, techId: draggingData.id, save: true, technician: draggingData }));
    }
  };

  const HandleEventClick = (e, event) => {
    if (event.startTime === event.metadata.prevStartTime) {
      dispatch(showEditModal(event));
    }
  };

  return {
    HandleEventDoubleClick,
    HandleEventRepositionMouseDown,
    HandleEventResizeMouseDown,
    HandleMouseUp,
    HandleEventClick
  };
};

export default EventLane;

EventLane.propTypes = {
  technician: PropTypes.object.isRequired
};
