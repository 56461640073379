import React from 'react';
import I18n from '@utils/i18n';
import PropTypes from 'prop-types';

function DetailsCell({ dataItem }) {
  return (
    <td>
      {Object.keys(dataItem.assetDetails).length === 0 ? (
        <span>{dataItem.details}</span>
      ) : (
        Object.entries(dataItem.assetDetails).map(([key, value]) => (
          <div key={key}>
            <strong>{key}</strong>: {value}
          </div>
        ))
      )}
    </td>
  );
}

DetailsCell.propTypes = {
  dataItem: PropTypes.shape({
    assetDetails: PropTypes.object,
    details: PropTypes.string
  }).isRequired
};

export default DetailsCell;
