import { createSlice } from '@reduxjs/toolkit';

export const status = {
  INIT: 'INIT',
  READY: 'READY',
  SAVING: 'SAVING',
  ERROR: 'ERROR'
};
const initialState = {
  targetEvent: null,
  formData: null,
  status: status.INIT
};
export const modalSlice = createSlice({
  name: 'edit',
  initialState,
  reducers: {
    showEditModal: (state, action) => {
      state.targetEvent = action.payload;
      state.formData = { ...action.payload };
    },
    hideEditModal: (state) => {
      state.targetEvent = null;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    editValue: (state, action) => {
      state.formData[action.payload.field] = action.payload.value;
    },
    editTargetEvent: (state, action) => {
      state.targetEvent = action.payload;
    }
  }
});

export const { showEditModal, editValue, hideEditModal, setStatus, editTargetEvent } = modalSlice.actions;

export default modalSlice.reducer;
