import React from 'react';
import clsx from 'classnames';
import I18n, { translate } from '@utils/i18n';
import { DashboardEntityType } from '../context/constants';

const namespace = 'features.dashboards.home';
const modalNamespace = 'features.dashboards.home.modals';

export default function getModalInfo({
  entityType,
  status,
  data = [],
  month,
  rangeFromToday,
  rightActionLink,
  timeFrame = I18n.t('features.dashboards.home.timeFrame')
}) {
  const formattedCurrency = data
    .reduce((acc, item) => acc + parseFloat(item.sumAmount || '0'), 0)
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  switch (entityType) {
    case DashboardEntityType.Inspections:
      return {
        title: translate('inspections_num', { namespace: modalNamespace, count: data.length }),
        subTitleView: status && (
          <span className="subTitleContainer">
            <i className={status.icon} style={{ color: status.statusColor }} />
            {status.name}
          </span>
        ),
        timeFrame: rangeFromToday ? translate('today', { namespace: modalNamespace }) : month,
        rightActionButton: (
          <a
            href={rightActionLink}
            className="qmb-control"
            title={translate('inspections.expand_title', { namespace })}>
            <i className="fa-light fa-chart-mixed" />
            <span>{translate('inspection_insights', { namespace: modalNamespace })}</span>
          </a>
        )
      };
    case DashboardEntityType.WorkOrders:
      let titleText = '';
      if (status) {
        titleText = translate('work_orders_num', { namespace: modalNamespace, count: data.length });
      } else {
        titleText = translate('services_num', { namespace: modalNamespace, count: data.length });
      }
      return {
        title: titleText,
        subTitleView: status && (
          <span className="subTitleContainer">
            <i className={status.icon} style={{ color: status.statusColor }} />
            {status.name}
          </span>
        ),
        timeFrame: rangeFromToday ? translate('today', { namespace: modalNamespace }) : month
      };
    case DashboardEntityType.Invoices:
    case DashboardEntityType.PaidInvoices:
      return {
        title: translate('invoices_num', { namespace: modalNamespace, count: data.length }),
        subTitleView: (
          <span className="subTitleContainer">
            <span className="totalLabel">{translate('total', { namespace: modalNamespace })}</span>
            <span className="totalValue">{formattedCurrency}</span>
          </span>
        ),
        timeFrame,
        rightActionButton: (
          <a
            href={rightActionLink}
            className="qmb-control"
            title={I18n.t('features.dashboards.home.invoices.expand_title')}>
            <i className="fa-light fa-chart-mixed" />
            <span>{translate('invoice_insights', { namespace: modalNamespace })}</span>
          </a>
        )
      };
    case DashboardEntityType.Deficiencies:
      return {
        title: translate('deficiencies_num', { namespace: modalNamespace, count: data.length }),
        subTitleView: (
          <span className={clsx('subTitleContainer', { '--critical': status.name === 'critical' })}>{status.name}</span>
        ),
        timeFrame,
        rightActionButton: (
          <a
            href={rightActionLink}
            className="qmb-control"
            title={I18n.t('features.dashboards.home.invoices.expand_title')}>
            <i className="fa-light fa-chart-mixed" />
            <span>{translate('deficiencies_insights', { namespace: modalNamespace })}</span>
          </a>
        )
      };
    case DashboardEntityType.Payments:
      return {
        title: translate('payments_num', { namespace: modalNamespace, count: data.length }),
        subTitleView: (
          <span className="subTitleContainer">
            <span className="totalLabel">{translate('total', { namespace: modalNamespace })}</span>
            <span className="totalValue">{formattedCurrency}</span>
          </span>
        ),
        timeFrame,
        rightActionButton: (
          <a
            href={rightActionLink}
            className="qmb-control"
            title={I18n.t('features.dashboards.home.payments.expand_title')}>
            {I18n.t('features.dashboards.home.payments.view_history')}
          </a>
        )
      };
    case DashboardEntityType.Proposals:
      return {
        title: translate('proposals_num', { namespace: modalNamespace, count: data.length }),
        subTitleView: (
          <span className="subTitleContainer">
            <span className="totalLabel">{translate('total', { namespace: modalNamespace })}</span>
            <span className="totalValue">{formattedCurrency}</span>
          </span>
        ),
        timeFrame,
        rightActionButton: (
          <a
            href={rightActionLink}
            className="qmb-control"
            title={I18n.t('features.dashboards.home.invoices.expand_title')}>
            <i className="fa-light fa-chart-mixed" />
            <span>{translate('proposals_insights', { namespace: modalNamespace })}</span>
          </a>
        )
      };
    case DashboardEntityType.HighPriorities:
      return {
        title: translate('high_priority_items', { namespace: modalNamespace, count: data.length }),
        subTitleView: null,
        timeFrame: translate('all_time', { namespace: modalNamespace })
      };
    default:
      return { title: '', subTitleView: '' };
  }
}
