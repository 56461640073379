import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import FilterPanel from '@components/FilterPanel';
import { buildInitialFiltersState, reducer, actionTypes } from './reducer';
import Total from './nested/Total';
import History from './nested/History';
import OutstandingCustomers from './nested/OutstandingCustomers';
import { saveCacheToLocalStorage, cacheFromLocalStorage } from './helpers/localStorage';
import { invoiceInsightsAnalyticReportsPath } from 'routes';
import useFilters from './hooks/useFilters';

const DEFAULT_CACHE_KEY = 'invoice_insights';

function InvoiceInsights({ currency, cacheKey, dataUrl }) {
  const kendoCacheKey = `kendo/${cacheKey}`;
  const [state, dispatch] = useReducer(reducer, {
    tableFingerprint: undefined,
    filters: cacheFromLocalStorage(kendoCacheKey) || buildInitialFiltersState(),
    isLoading: true
  });

  const filtersRef = useRef();
  filtersRef.current = state.filters;

  useEffect(() => {
    fetch(
      `${invoiceInsightsAnalyticReportsPath()}?${new URLSearchParams({
        account: state.filters.account.value,
        building: state.filters.building.value,
        issueDateStart: state.filters.issueDate.startDate,
        issueDateEnd: state.filters.issueDate.endDate,
        technician: state.filters.technician.value,
        technicianTeam: state.filters.technicianTeam.value,
        invoiceCategory: state.filters.invoiceCategory.value
      })}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: actionTypes.DATA_LOADED, data });
      });
    saveCacheToLocalStorage(kendoCacheKey, filtersRef.current);
  }, [state.filters]);

  const onResetFilters = () => {
    dispatch({ type: actionTypes.FILTERS_RESET });
  };

  const onResetCache = () => {
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(kendoCacheKey);
    window.location.reload();
  };

  const filters = useFilters(state.filters, dispatch);

  return (
    <>
      <FilterPanel onResetFilters={onResetFilters} onResetCache={onResetCache} filters={filters} />
      <hr className="workspace__split" />
      <div className="dashboard__contain">
        <a href="/" className="qmb-control--inline">
          <i className="fa-regular fa-arrow-left" /> {I18n.t('features.dashboards.invoice_insights.back')}
        </a>
        <div className="dashboard__widgets">
          <Total
            currency={currency}
            isLoading={state.isLoading}
            title={I18n.t('features.dashboards.invoice_insights.total_invoiced.title')}
            {...state.totalInvoiced}
          />
          <Total
            currency={currency}
            isLoading={state.isLoading}
            title={I18n.t('features.dashboards.invoice_insights.total_due.title')}
            {...state.totalDue}
          />
          <OutstandingCustomers
            currency={currency}
            isLoading={state.isLoading}
            customers={state.outstandingCustomers}
          />
          <History
            isLoading={state.isLoading}
            title={I18n.t('features.dashboards.invoice_insights.invoice_issue_history.title')}
            series={state.issueHistory}
            months={state.months}
            currency={currency}
            stack={false}
          />
          <History
            isLoading={state.isLoading}
            title={I18n.t('features.dashboards.invoice_insights.invoice_history_by_tech.title')}
            series={state.historyByTech}
            months={state.months}
            currency={currency}
          />
          <History
            isLoading={state.isLoading}
            title={I18n.t('features.dashboards.invoice_insights.paid_invoice_history.title')}
            series={state.paidHistory}
            months={state.months}
            currency={currency}
          />
        </div>
      </div>
    </>
  );
}

InvoiceInsights.propTypes = {
  currency: PropTypes.string,
  cacheKey: PropTypes.string
};

InvoiceInsights.defaultProps = {
  currency: '$',
  cacheKey: DEFAULT_CACHE_KEY
};

export default InvoiceInsights;
