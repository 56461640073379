import React, { useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import I18n, { translate } from '@utils/i18n';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import weekData from 'cldr-core/supplemental/weekData.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import { Window } from '@progress/kendo-react-dialogs';
import {
  hideAddPassengerPopup,
  hideFleetTrackingModal,
  showAddPassengerPopup,
  updateDrivers, updateSelectedTech
} from "./Redux/fleetTrackingSlice";

import esMessages from '../../locales/es.json';
import frMessages from '../../locales/fr.json';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  deleteFleetTrackingPassengers,
  fetchTechnicianLocations,
  fetchTechnicians,
  updateFleetTrackingPassengers
} from "./DAL/dataAccess";
import { Popup } from "@progress/kendo-react-popup";
import { addError, setStatus, setTechnicians, status as schedulerStatuses } from "./Redux/schedulerSlice";

load(weekData, esCaGregorian, esDateFields, frCaGregorian, frDateFields); // to i18n DateTimePicker component
loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');

const namespace = 'features.map_scheduler';

function FleetTrackingModal() {


  const dispatch = useDispatch();
  const selectedTech = useSelector((state) => state.fleet.selectedTech);
  const technicians = useSelector((state) => state.scheduler.technicians)

  const globalNav = document.getElementById('global-nav').getBoundingClientRect();
  const globalHeader = document.getElementById('global-header').getBoundingClientRect();

  const filters = useSelector((state) => state.map.filters);
  const filterRef = useRef(filters);

  const borderless = {border: 'none', outline: 'none'};

  const showPassengerPopup = useSelector((state) => state.fleet.popup);

  const toggleDialog = () => {
    dispatch(hideFleetTrackingModal());
    dispatch(hideAddPassengerPopup());
  };

  const handleAddPassenger = () => {
    showPassengerPopup ? handleCancelAddPassenger() : dispatch(showAddPassengerPopup());
  }

  const handleCancelAddPassenger = () => {
    document.querySelector('#addPassengerError').style.display = 'none';
    dispatch(hideAddPassengerPopup());
  }

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    // 2nd child in form (dropdown since fieldset counts)
    const techId = e.target[1].value;
    const vehicleId = selectedTech.vehicle_id;
    if(technicians.find((tech) => `${tech.id}` === techId)?.fleet_manager_vehicle_id) {
      if(confirm(translate('fleet_tracking_modal.confirm', { namespace }))) {
        await executePassengerUpdate(techId, vehicleId);
      }
      return;
    }
    await executePassengerUpdate(techId, vehicleId);
  }

  const handleDeletePassenger = async (id) => {
    if(confirm(translate('fleet_tracking_modal.confirm_delete', { namespace }))) {
      const deleted = await executePassengerDelete(id);
      if(!deleted) {
        alert(translate('fleet_tracking_modal.delete_failure', { namespace }));
        return;
      }
      await updateFleetData();
    }
  }

  const showDeletePassenger = (id) => {
    document.querySelector(`#deletePassenger${id}`).style.display = 'inline-block';
  }

  const hideDeletePassenger = (id) => {
    document.querySelector(`#deletePassenger${id}`).style.display = 'none';
  }

  const executePassengerUpdate = async (techId, vehicleId) => {
    const updated = await updateTechnicianVehicleId(techId, vehicleId);
    if(!updated) {
      document.querySelector('#addPassengerError').style.display = 'block';
      return;
    }
    await updateFleetData();
    handleCancelAddPassenger();
  }

  const updateTechnicianVehicleId = async (techId, vehicleId) => {
    const data = await updateFleetTrackingPassengers(techId, vehicleId);
    return data.success;
  }

  const executePassengerDelete = async(id) => {
    const data = await deleteFleetTrackingPassengers(id);
    return data.success;
  }

  const updateFleetData = async () => {
    const results = await fetchTechnicianLocations();
    dispatch(updateDrivers(results));
    fetchTechnicians(filters)
      .then((result) => {
        dispatch(setTechnicians(result));
      })
      .catch((error) => {
        console.error(error);
      });
    dispatch(updateSelectedTech({ id: selectedTech.id }));
  }

  return (
    <Window
      title={<Title selectedTech={selectedTech} />}
      onClose={toggleDialog}
      minWidth={240}
      initialWidth={480}
      maxWidth={480}
      initialHeight={320}
      minHeight={320}
      initialLeft={globalNav.right + 16}
      initialTop={globalHeader.bottom + 16}
      minimizeButton={() => null}
      maximizeButton={() => null}
      restoreButton={() => null}
      className="qmb-dialog">
      <table className="qmb-table--x-full pt-1">
        <tbody>
          <tr style={borderless}>
            <td className="list__item" style={borderless}>
              <span className="item__label">Driver</span>
              <br />
              <div style={{ display: "inline-flex", flexDirection: "column", margin: "0px" }}>
                  <span>
                    <TechnicianInfo technician={selectedTech} technicianId={selectedTech.id} />
                  </span>
              </div>
            </td>
            <td className="list__item" style={borderless}>
              <span className="item__label">Vehicle Status</span>
              <br />
              <div style={{ display: "inline-flex", flexDirection: "column", margin: "0px" }}>
                  <span>
                    {selectedTech.vehicle_status}
                  </span>
              </div>
            </td>
          </tr>
          <tr style={borderless}>
            <td className="list__item" style={borderless}>
              <span className="item__label">Passengers</span>
              <br />
              <div style={{ display: "inline-flex", flexDirection: "column", margin: "0px" }}>
                {selectedTech.passengers.map((passenger) => (
                  <div onMouseEnter={() => showDeletePassenger(passenger.id)}
                       onMouseLeave={() => hideDeletePassenger(passenger.id)}
                       onClick={() => handleDeletePassenger(passenger.id)}
                       style={{ cursor: "pointer" }}>
                    <span key={passenger.id}>
                      <TechnicianInfo technician={passenger} technicianId={passenger.id} />
                    </span>
                    <i className="fa-light fa-xmark v-align-middle pl-1" id={`deletePassenger${passenger.id}`} style={{ display: "none" }} />
                  </div>
                ))}
                <button
                  className={`qmb-control--icon${showPassengerPopup ? " fa-light fa-circle-xmark" : "--add"} filters__toggle-menu`}
                  id="addPassengerButton" type="button" onClick={handleAddPassenger}
                  style={{marginLeft: '-0.8rem'}}/>
              </div>

            </td>
            <td className="list__item v-align-top" style={borderless}>
              <span className="item__label">Last Update</span>
              <br />
              <div style={{ display: "inline-flex", flexDirection: "column", margin: "0px" }}>
                  <span>
                    {new Date(selectedTech.last_update + 'Z').toLocaleString()}
                  </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {showPassengerPopup && <PassengerPopup
                                selectedTech={selectedTech}
                                anchor={document.querySelector('#addPassengerButton')}
                                technicians={technicians}
                                handleCancelAddPassenger={handleCancelAddPassenger}
                                handleFormSubmission={handleFormSubmission} />}
    </Window>
  );
}

function Title({ selectedTech }) {
  const style = { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" };

  return (
    <div style={style}>
      <span>
        <i className="fa-solid fa-truck" style={{ color: selectedTech.color }} />
        &nbsp;
        <strong>{selectedTech.vehicle_name}</strong>
      </span>
    </div>
  );
}

function TechnicianInfo({ technician, technicianId }) {
  if (!technicianId) {
    return (
      <span className="option__tech--unassigned">
        <i className="fa-light fa-user-helmet-safety" /> {I18n.t("generic.unassigned")}
      </span>
    );
  }
  if (!technician.photoUrl) {
    return (
      <div style={{ height: "24px", display: "inline-block" }} className="option__tech">
        <span className="qmb-avatar--16 v-align-middle">
          <svg viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
              {technician.name[0]}
            </text>
          </svg>
        </span>
        <span className="v-align-middle">&nbsp;{technician.name}</span>
      </div>
    );
  }
  return (
    <div style={{ height: "24px", display: "inline-block"}} className="option__tech v-align-middle">
      <span className="qmb-avatar--16 v-align-middle">
        <img
          alt={technician.name[0]}
          style={{ borderRadius: "50%", border: `1px solid ${technician.color}`, boxSizing: "border-box" }}
          src={technician.photoUrl}
        />
      </span>
      <span className="v-align-middle">&nbsp;{technician.name}</span>
    </div>
  );
}

function PassengerPopup({ selectedTech, technicians, anchor, handleCancelAddPassenger, handleFormSubmission}) {
  return <Popup show anchor={anchor} onClose={handleCancelAddPassenger} popupClass="qmb-popup--action-list ">
    <form className="k-form" onSubmit={handleFormSubmission}>
      <fieldset>
        <p id="addPassengerError" style={{ color: "red", display: "none" }}>
          {translate('fleet_tracking_modal.add_passenger_error', namespace)}
        </p>
        <div className="qmb-select--blank">
          <select id="addPassengerDropdown" name="addPassengerDropdown">
            {technicians.filter((tech) => tech.id !== selectedTech.id && tech.fleet_manager_vehicle_id !== selectedTech.vehicle_id).map((tech) => (
              <option key={tech.id} value={tech.id}>{tech.name}</option>
            ))}
          </select>
        </div>
      </fieldset>
      <br />
      <button className="btn btn-small pull-left" type="submit">Add</button>
    </form>
  </Popup>
}

export default FleetTrackingModal;
