import { useMemo } from 'react';
import {
  THIS_WEEK,
  NEXT_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  NEXT_MONTH,
  LAST_MONTH,
  THIS_QUARTER,
  NEXT_QUARTER,
  LAST_QUARTER,
  THIS_YEAR,
  LAST_YEAR,
  CUSTOM_RANGE,
  LAST_THIRTY_DAYS
} from '@constants/Kendo/rangeOptions';
import { actionTypes } from '../reducer';

export default function useFilters(filters, dispatch) {
  const onChangeSearchFilter = (fieldName) => {
    return (e) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, field: fieldName, value: e.sender.element[0].value });
    };
  };

  const onToggleFilter = (fieldName) => {
    return () => {
      dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
    };
  };

  const onChangeDateFilter = (fieldName) => {
    return (dateFieldName, value) => {
      dispatch({ type: actionTypes.IS_LOADING });

      dispatch({
        type: actionTypes.DATE_RANGE_FILTER_CHANGED,
        field: fieldName,
        dateField: dateFieldName,
        value
      });
    };
  };

  return useMemo(
    () => [
      {
        field: 'account',
        locale: 'account',
        type: 'searchSelect',
        active: filters.account.active,
        value: filters.account.value,
        onChange: onChangeSearchFilter('account'),
        onToggle: onToggleFilter('account')
      },
      {
        field: 'building',
        locale: 'building',
        type: 'searchSelect',
        active: filters.building.active,
        value: filters.building.value,
        onChange: onChangeSearchFilter('building'),
        onToggle: onToggleFilter('building')
      },
      {
        field: 'issueDate',
        locale: 'issue_date',
        type: 'dateRangePicker',
        active: true,
        locked: true,
        dateRanges: [
          THIS_WEEK,
          NEXT_WEEK,
          LAST_WEEK,
          THIS_MONTH,
          NEXT_MONTH,
          LAST_MONTH,
          LAST_THIRTY_DAYS,
          THIS_QUARTER,
          NEXT_QUARTER,
          LAST_QUARTER,
          THIS_YEAR,
          LAST_YEAR,
          CUSTOM_RANGE
        ],
        values: {
          rangeType: filters.issueDate.rangeType,
          startDate: filters.issueDate.startDate,
          endDate: filters.issueDate.endDate
        },
        onChange: onChangeDateFilter('issueDate'),
        onToggle: onToggleFilter('issueDate')
      },
      {
        field: 'technician',
        locale: 'technician',
        type: 'searchSelect',
        active: filters.technician.active,
        value: filters.technician.value,
        onChange: onChangeSearchFilter('technician'),
        onToggle: onToggleFilter('technician')
      },
      {
        field: 'technicianTeam',
        locale: 'technician_team',
        type: 'searchSelect',
        active: filters.technicianTeam.active,
        value: filters.technicianTeam.value,
        onChange: onChangeSearchFilter('technicianTeam'),
        onToggle: onToggleFilter('technicianTeam')
      },
      {
        field: 'invoiceCategory',
        locale: 'invoice_category',
        type: 'searchSelect',
        active: filters.invoiceCategory.active,
        value: filters.invoiceCategory.value,
        onChange: onChangeSearchFilter('invoiceCategory'),
        onToggle: onToggleFilter('invoiceCategory')
      }
    ],
    [filters]
  );
}
