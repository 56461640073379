import React, { useState } from "react";
import { buildingPath } from "../../../../routes";
import { translate } from '@utils/i18n';
function VisitDetails({ visit }) {
    var _a, _b;
    const namespace = 'features.modals.visit_modal.details';
    const [collapsed, setCollapsed] = useState(true);
    const onToggleExpand = () => {
        setCollapsed((old) => !old);
    };
    const TechnicianInfo = ({ technician }) => {
        if (!technician) {
            return (React.createElement("span", { className: "option__tech--unassigned" }, React.createElement("i", { className: "fa-light fa-user-helmet-safety" }), " Unassigned"));
        }
        if (!technician.photoUrl) {
            return (React.createElement(React.Fragment, null, React.createElement("span", { className: "qmb-avatar--16" }, React.createElement("svg", { viewBox: "0 0 16 16" }, React.createElement("circle", { cx: "8", cy: "8", r: "8", style: { fill: technician.color } }), React.createElement("text", { x: "50%", y: "55%", dominantBaseline: "middle", textAnchor: "middle" }, technician.name[0]))), technician.name));
        }
        return (React.createElement("span", { className: "option__tech" }, React.createElement("span", { className: "qmb-avatar--16" }, React.createElement("img", { alt: technician.name[0], style: { borderRadius: '50%', border: `1px solid ${technician.color}`, boxSizing: 'border-box' }, src: technician.photoUrl })), technician.name));
    };
    const NestedDetails = (details, depth) => {
        return (React.createElement(React.Fragment, null, Object.keys(details).map((key) => {
            const value = details[key];
            if (typeof value === 'string') {
                return (React.createElement("div", { key: key, className: "list__item", style: { marginLeft: `${depth}rem` } }, React.createElement("span", { className: "item__label" }, translate(key, { namespace }), ":"), React.createElement("span", null, value)));
            }
            if (typeof value === 'object' && value !== null) {
                return (React.createElement("span", { key: key }, React.createElement("span", { className: "item__label", key: key }, translate(key, { namespace }), ":"), NestedDetails(value, (depth + 1))));
            }
            return null;
        })));
    };
    return (React.createElement(React.Fragment, null, React.createElement("div", { className: "dialog__section" }, React.createElement("ul", { className: "dialog__list--scheduling" }, React.createElement("li", { className: "dialog__item--title" }, React.createElement("a", { href: buildingPath(visit.building.id), target: "_blank", rel: "noreferrer" }, (_a = visit.building) === null || _a === void 0 ? void 0 :
        _a.name, React.createElement("i", { className: "fa-light fa-arrow-up-right-from-square" }))), React.createElement("li", { className: "dialog__item" }, (_b = visit.building) === null || _b === void 0 ? void 0 : _b.address), React.createElement("li", { className: "dialog__item" }, React.createElement("span", null, React.createElement("i", { style: { color: visit.status.color }, className: visit.status.icon }), visit.status.label), "\u2013", visit.start.toLocaleString()), React.createElement("li", { className: "dialog__item--tech" }, React.createElement(TechnicianInfo, { technician: visit.technician })))), React.createElement("div", { className: "dialog__section" }, React.createElement("button", { type: "button", onClick: onToggleExpand, className: "qmb-control--sm--detail-toggle" }, collapsed ? translate('expand', { namespace }) : translate('collapse', { namespace }), React.createElement("i", { className: collapsed
            ? 'fa-light fa-arrow-up-right-and-arrow-down-left-from-center'
            : 'fa-light fa-arrow-down-left-and-arrow-up-right-to-center' })), React.createElement("div", { className: "dialog__header--section" }, React.createElement("h2", { className: "dialog__title" }, translate('visit_details', { namespace }))), React.createElement("div", { className: `dialog__list details-box ${collapsed && '--collapsed'}` }, NestedDetails(visit.details, 0)))));
}
export default VisitDetails;
