import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import mime from 'mime';
import { ExternalDropZone, Upload } from '@progress/kendo-react-upload';
import toast, { Toaster } from 'react-hot-toast';
import { Loader, LoaderSizes } from '../Loader/Loader';
import './UploadZone.scss';

const namespace = 'features.upload_zone';

export default function UploadZone({
  id,
  onFileAdded,
  saveUrl,
  acceptedFormats,
  maximumSize,
  additionalData,
  additionalHeaders,
  fieldName
}) {
  const uploadRef = useRef(null);
  const mimeExtension = acceptedFormats.map((format) => `.${mime.getExtension(format)}`);
  const [isUploading, setIsUploading] = useState(false);
  const filesUploading = useRef(0);

  useEffect(() => {
    const uploadDropZone = document.querySelector(`#uploadDropZone${id} .k-dropzone-inner`);
    const listener = () => {
      document.getElementById(id).click();
    };
    uploadDropZone.addEventListener('click', listener);
    return () => {
      uploadDropZone.removeEventListener('click', listener);
    };
  }, []);

  const onBeforeUpload = (event) => {
    setIsUploading(true);
    event.additionalData = additionalData;
    event.headers = { ...(event.headers || {}), ...additionalHeaders };
    filesUploading.current += 1;
  };

  const onUploadFinished = (event) => {
    onFileAdded(event.response.response);
    filesUploading.current -= 1;
    if (filesUploading.current === 0) setIsUploading(false);
  };

  const onValidationError = (e) => {
    if (e.affectedFiles.some((file) => file.validationErrors?.length)) {
      toast.error(translate('upload_files_error', { namespace }), {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  return (
    <>
      <Toaster position="bottom-right" />
      <div className="uploadZoneContainer">
        <ExternalDropZone
          customHint={<UploadCustomHint />}
          customNote={<UploadCustomNote fileTypes={mimeExtension} maximumSize={maximumSize} />}
          id={`uploadDropZone${id}`}
          uploadRef={uploadRef}
          style={{ marginBottom: '2.4rem', marginRight: '2.4rem', borderStyle: 'dashed' }}
        />
        <Upload
          id={id}
          className="uploadZoneContainer"
          ref={uploadRef}
          batch={false}
          multiple
          disabled={isUploading}
          restrictions={{ allowedExtensions: mimeExtension, maxFileSize: maximumSize * 1024 * 1024 }}
          showFileList={false}
          withCredentials
          onStatusChange={onUploadFinished}
          onAdd={onValidationError}
          saveField={fieldName}
          onBeforeUpload={onBeforeUpload}
          saveUrl={saveUrl}
        />
        {isUploading && (
          <div className="loaderContainer">
            <Loader size={LoaderSizes.large} />
          </div>
        )}
      </div>
    </>
  );
}

function UploadCustomHint() {
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <i style={{ fontSize: '32px', color: '#1967D2' }} className="fa-light fa-cloud-arrow-up" />
      </div>
      <div style={{ textAlign: 'center' }}>
        <span style={{ color: '#1967D2' }}>{translate('upload_hint_1', { namespace })}</span>
        &nbsp;
        {translate('upload_hint_2', { namespace })}
        <br />
        {translate('upload_hint_3', { namespace })}
      </div>
    </div>
  );
}

function UploadCustomNote({ fileTypes, maximumSize }) {
  const textFileTypes = fileTypes.join(', ');
  return (
    <div style={{ textAlign: 'center' }}>
      {translate('upload_note_1', { namespace, fileTypes: textFileTypes })}
      <br />
      {translate('upload_note_2', { namespace, maximumSize: maximumSize / 1024 })}
    </div>
  );
}

UploadZone.propTypes = {
  id: PropTypes.string.isRequired,
  onFileAdded: PropTypes.func.isRequired,
  acceptedFormats: PropTypes.array,
  saveUrl: PropTypes.string,
  maximumSize: PropTypes.number,
  additionalData: PropTypes.object,
  additionalHeaders: PropTypes.object,
  fieldName: PropTypes.string
};

UploadZone.defaultProps = {
  acceptedFormats: [],
  saveUrl: '',
  maximumSize: 5242880,
  additionalData: {},
  additionalHeaders: {},
  fieldName: 'file'
};

UploadCustomNote.propTypes = {
  fileTypes: PropTypes.array.isRequired,
  maximumSize: PropTypes.number.isRequired
};
