import React, { useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Window } from '@progress/kendo-react-dialogs';
import { translate } from '@utils/i18n';
import { inspectionPath, workOrderPath, editTechnicianDayOffPath, editCompanyHolidayPath } from 'routes';
import Details from './Details';
import Form from './Form';
import { CalendarContext } from '../../CalendarContext';

const namespace = 'features.calendar.edit_modal';

function EditModal({ onCloseModal, event, technician }) {
  const { timeZone } = useContext(CalendarContext);

  const formData = useMemo(() => ({ ...event }), [event]);
  const height = useMemo(() => (event.type === 'TimeOff' ? 300 : 640), []);

  useEffect(() => {
    document.querySelector('.k-overlay').addEventListener('click', onCloseModal);
  }, []);

  return (
    <Window
      modal
      title={<Title event={event} />}
      onClose={onCloseModal}
      minWidth={240}
      initialWidth={480}
      maxWidth={480}
      initialHeight={height}
      minHeight={height}
      minimizeButton={() => null}
      maximizeButton={() => null}
      restoreButton={() => null}
      className="qmb-dialog">
      <Details technician={technician} event={event} />
      {event.type !== 'TimeOff' && (
        <>
          <hr className="dialog__split" />
          <Form timeZone={timeZone} onCloseModal={onCloseModal} initialFormData={formData} />
        </>
      )}
    </Window>
  );
}

function Title({ event }) {
  const style = { width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' };

  if (event.type === 'Inspection')
    return (
      <div style={style}>
        <span className="dialog__faux-title">
          <i className="fa-light fa-clipboard-check" />
          {translate('inspection', { namespace })}
        </span>
        <a href={inspectionPath(event.objectId)} target="_blank" className="qmb-control--sm" rel="noreferrer">
          {translate('details_link', { namespace })}
          <i className="fa-light fa-arrow-up-right-from-square" />
        </a>
      </div>
    );

  if (event.type === 'InspectionVisit')
    return (
      <div style={style}>
        <span className="dialog__faux-title">
          <i className="fa-light fa-clipboard-check" />
          {translate('inspection', { namespace })}
        </span>
        <a href={inspectionPath(event.inspectionId)} target="_blank" className="qmb-control--sm" rel="noreferrer">
          {translate('details_link', { namespace })}
          <i className="fa-light fa-arrow-up-right-from-square" />
        </a>
      </div>
    );

  if (event.type === 'Visit')
    return (
      <div style={style}>
        <span className="dialog__faux-title">
          <i className="fa-light fa-screwdriver-wrench" />
          {translate('work_order', { namespace })}
        </span>
        <a href={workOrderPath(event.workOrderId)} target="_blank" className="qmb-control--sm" rel="noreferrer">
          {translate('details_link', { namespace })}
          <i className="fa-light fa-arrow-up-right-from-square" />
        </a>
      </div>
    );

  if (event.display === 'background')
    return (
      <div style={style}>
        <span className="dialog__faux-title">
          <i className="fa-light fa-umbrella-beach" />
          {event.title}
        </span>
        <a href={editCompanyHolidayPath(event.objectId)} target="_blank" className="qmb-control--sm" rel="noreferrer">
          {translate('details_link', { namespace })}
          <i className="fa-light fa-arrow-up-right-from-square" />
        </a>
      </div>
    );

  return (
    <div>
      <span className="dialog__faux-title">
        <i className="fa-light fa-umbrella-beach" />
        {translate('time_off', { namespace })}
      </span>
      <a href={editTechnicianDayOffPath(event.objectId)} target="_blank" className="qmb-control--sm" rel="noreferrer">
        {translate('details_link', { namespace })}
        <i className="fa-light fa-arrow-up-right-from-square" />
      </a>
    </div>
  );
}

Title.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
    display: PropTypes.string,
    objectId: PropTypes.number,
    workOrderId: PropTypes.number,
    title: PropTypes.string,
    inspectionId: PropTypes.number
  }).isRequired
};

EditModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  event: PropTypes.shape({
    type: PropTypes.string
  }).isRequired,
  technician: PropTypes.shape({
    photoUrl: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default EditModal;
