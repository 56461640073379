import React, { useContext, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3';
import adaptivePlugin from '@fullcalendar/adaptive';
import I18n from '@utils/i18n';
import Header from './nested/Header';
import Event from './nested/Event';
import CellHeader from './nested/CellHeader';
import { CalendarContext, CalendarProvider } from './CalendarContext';
import useEvents from './hooks/useEvents';
import TimeOffModal from '../Modals/TimeOffModal';
import VisitEditModal from "../Modals/VisitModal";

function Calendar() {
  const {
    timeZone,
    state: { errorMessage, view, loading, modalOpen, modalParams},
    actions: { onEventChange, onDateRangeChange, reloadEvents, closeModal }
  } = useContext(CalendarContext);

  const events = useEvents();

  const modal = useMemo(() => {

    if (!modalParams) {
      return null;
    }

    if (modalParams.type === 'TimeOff') {
      return (
        <TimeOffModal
          id={modalParams.id}
          onClose={() => {
            closeModal();
          }}
        />
      );
    }

    return (
      <VisitEditModal
        id={modalParams.id}
        type={modalParams.type}
        onClose={() => {
          closeModal();
        }}
        onSave={(visit) => {
          reloadEvents(); // Not the most optimal way, but the simplest without major changes
          closeModal();
        }}
      />
    );

  }, [modalParams]);

  return (
    <>
      <div>{errorMessage}</div>
      <Header />
      {loading && (
        <div className="qmb-loading--128--page" data-title={I18n.t('generic.loading')}>
          <svg role="img">
            <use href="/map.svg#load-spinner" />
          </svg>
        </div>
      )}
      <FullCalendar
        // TODO: move in env vars
        schedulerLicenseKey="0879361028-fcs-1723636422"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, luxonPlugin, adaptivePlugin]}
        headerToolbar={{
          left: 'title',
          right: 'prev,today,next dayGridMonth,timeGridWeek,timeGridDay'
        }}
        eventContent={EventWrapper}
        dayCellContent={CellHeaderWrapper}
        initialView={view}
        events={events}
        datesSet={onDateRangeChange}
        eventDisplay="block"
        eventStartEditable
        eventChange={onEventChange}
        contentHeight="auto"
        dragScroll="true"
        showNonCurrentDates={false}
        timeZone={timeZone}
      />
      {modalOpen && modal}
    </>
  );
}

function EventWrapper(fcProps) {
  return <Event fcProps={fcProps} />;
}
function CellHeaderWrapper(fcProps) {
  return <CellHeader fcProps={fcProps} />;
}

function CalendarWrapper(props) {
  return (
    <CalendarProvider {...props}>
      <Calendar />
    </CalendarProvider>
  );
}

export default CalendarWrapper;
