import { createSlice } from '@reduxjs/toolkit';

export const status = {
  NONE: 'NONE',
  DRAGGING: 'DRAGGING'
};

export const types = {
  NONE: 'NONE',
  EVENT: 'EVENT',
  TECHNICIAN: 'TECHNICIAN',
  VISIT: 'VISIT',
  UNKNOWN: 'UNKNOWN'
};

const initialState = {
  status: status.NONE,
  type: types.NONE,
  mouseX: 0,
  mouseY: 0,
  data: null,
  element: null
};

export const draggingSlice = createSlice({
  name: 'dragging',
  initialState,
  reducers: {
    startDragging: (state, action) => {
      const { data, type } = action.payload;
      state.status = status.DRAGGING;
      state.data = data;
      state.type = type || types.UNKNOWN;
    },
    stopDragging: (state) => {
      state.status = status.NONE;
      state.type = types.NONE;
      state.data = null;
      state.element = null;
    }
  }
});

export const { startDragging, move, stopDragging } = draggingSlice.actions;

export default draggingSlice.reducer;
