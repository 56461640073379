import React, { useRef, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { translate } from '@utils/i18n';
import { CalendarContext } from '../../CalendarContext';

const namespace = 'features.calendar.header.statuses';

function Statuses() {
  const {
    state: { statuses },
    visitStatusesForHighlight,
    inspectionStatusesForSelect,
    permissions,
    actions: { onToggleStatus, toggleSelectAllStatuses, toggleUnselectAllStatuses }
  } = useContext(CalendarContext);

  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpen] = useState(false);

  const handleBodyClick = useCallback((event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const selectedStatusIds = useMemo(
    () =>
      inspectionStatusesForSelect
        .filter((status) => statuses[`Inspection${status.value}`])
        .concat(visitStatusesForHighlight.filter((status) => statuses[`Visit${status.value}`])),
    [statuses]
  );

  const onAddEventPopup = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <button type="button" className="qmb-control--menu" ref={anchor} onClick={onAddEventPopup}>
        {translate('title', { namespace })}
        {selectedStatusIds.length > 0 && (
          <span className="control__collection">
            {selectedStatusIds.slice(0, 5).map((status, index) => (
              <StatusIcon key={index} status={status} />
            ))}

            {selectedStatusIds.length > 5 && (
              <span className="control__count">&#43;{selectedStatusIds.length - 5}</span>
            )}
          </span>
        )}
      </button>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--actions--y-max">
          <div className="popup__section--group--y-fill">
            <h4 className="popup__title--group">{translate('inspection_statuses', { namespace })}</h4>
            <ul role="presentation">
              {inspectionStatusesForSelect.map((status) => (
                <li key={status.value}>
                  <label className="qmb-checkbox">
                    <input
                      type="checkbox"
                      onChange={onToggleStatus(`Inspection${status.value}`)}
                      checked={!!statuses[`Inspection${status.value}`]}
                    />
                    <StatusIcon status={status} />
                    {status.label}
                  </label>
                </li>
              ))}
            </ul>

            {permissions.hasService && (
              <>
                <h4 className="popup__title--group">{translate('wo_statuses', { namespace })}</h4>
                <ul role="presentation">
                  {visitStatusesForHighlight.map((status) => (
                    <li key={status.value}>
                      <label className="qmb-checkbox">
                        <input
                          type="checkbox"
                          onChange={onToggleStatus(`Visit${status.value}`)}
                          checked={!!statuses[`Visit${status.value}`]}
                        />
                        <StatusIcon status={status} />
                        {status.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <hr className="popup__split" />
          {visitStatusesForHighlight.length + inspectionStatusesForSelect.length > selectedStatusIds.length && (
            <div className="popup__section">
              <button type="button" className="qmb-control" onClick={toggleSelectAllStatuses}>
                {translate('select_all', { namespace })}
              </button>
            </div>
          )}
          {visitStatusesForHighlight.length + inspectionStatusesForSelect.length === selectedStatusIds.length && (
            <div className="popup__section">
              <button type="button" className="qmb-control" onClick={toggleUnselectAllStatuses}>
                {translate('unselect_all', { namespace })}
              </button>
            </div>
          )}
        </div>
      </Popup>
    </>
  );
}

function StatusIcon({ status }) {
  return (
    <span className="qmb-status">
      <i style={{ color: status.color }} className={status.icon} />
    </span>
  );
}

StatusIcon.propTypes = {
  status: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  }).isRequired
};

export default Statuses;
