import React, { useContext } from 'react';
import FilterPanel from '@components/FilterPanel';
import I18n from '@utils/i18n';
import Technicians from './Technicians';
import Statuses from './Statuses';
import View from './View';
import CreateEvent from '../CreateEvent';
import { CalendarContext } from '../../CalendarContext';
import useFilters from '../../hooks/useFilters';

const kendoCacheKey = 'kendo/calendar';

function Header() {
  const {
    actions: { onResetFilters },
    state: { loading }
  } = useContext(CalendarContext);

  const onResetCache = () => {
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(kendoCacheKey);
    window.location.reload();
  };

  const filters = useFilters();

  const style = loading ? { pointerEvents: 'none', opacity: '0.5', top: '0' } : { top: '0' };

  return (
    <>
      <FilterPanel onResetFilters={onResetFilters} onResetCache={onResetCache} filters={filters} />
      <div className="qmb-actions--sticky" style={style}>
        <div className="actions__group--flex">
          <CreateEvent withTitle />
          <Technicians />
          <Statuses />
        </div>
        <div className="actions__group">
          <View />
        </div>
      </div>
    </>
  );
}

export default Header;
