import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import { Toaster } from 'react-hot-toast';
import useDocuments from './hooks/useDocuments';
import Table from '../../components/Table/Table';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import Button, { ButtonSize, ButtonVariant } from '../../components/Button/Button';
import AddDocumentModal from './AddDocumentModal';
import { apiInternalInspectionDocumentsPath, apiInternalWorkOrderDocumentsPath } from '../../routes';
import getDocumentColumns from './getDocumentColumns';
import './DocumentList.scss';

const namespace = 'features.documents';

function DocSection({ subcontractor, onRequestDelete, idLoading, canDelete }) {
  const [expanded, setExpanded] = useState(true);
  return (
    <section
      className={`workspace__section documentListContaine ${expanded ? '--expanded  --active' : '--collapsed'}r`}>
      <button className="section__title" onClick={() => setExpanded(!expanded)}>
        <h2 className="section__label">{subcontractor.name}</h2>
        <hr className="section__split" />
      </button>

      <article className="section__content">
        <Table
          columns={getDocumentColumns({ onRequestDelete, idLoading, canDelete })}
          data={subcontractor.docs}
          isGrouped
        />
      </article>
    </section>
  );
}

function DocumentsList({ isPrime, workOrderId, inspectionId, canDelete, canCreate }) {
  const {
    addModalInfo,
    groupedDocuments,
    documentsType,
    confirmModalInfo,
    idLoading,
    groupedPrimeDocuments,
    onAddDocument,
    onRequestDelete,
    onRequestAdd,
    closeConfirmModal,
    closeAddModal
  } = useDocuments({
    workOrderId,
    inspectionId,
    isPrime
  });

  return (
    <>
      <Toaster position="bottom-right" />
      {!isPrime &&
        documentsType.map((documentType) =>
          groupedDocuments[documentType.id].length > 0 || canCreate ? (
            <section key={documentType.id} className="workspace__section documentListContainer">
              <header className="section__title">
                <h2 className="section__label">
                  {documentType.title}
                  {documentType.is_required && <span className="requiredFile"> *</span>}
                </h2>
                <hr className="section__split" />
              </header>

              <article className="section__content">
                {groupedDocuments[documentType.id].length > 0 && (
                  <Table
                    columns={getDocumentColumns({ onRequestDelete, idLoading, canDelete })}
                    data={groupedDocuments[documentType.id]}
                  />
                )}
                {canCreate && (
                  <Button
                    onClick={() => onRequestAdd(documentType)}
                    variant={ButtonVariant.BlueAccent}
                    size={ButtonSize.Full}>
                    <i className="fa-light fa-circle-plus" />
                    {translate('add_document', { namespace })}
                  </Button>
                )}
              </article>
            </section>
          ) : null
        )}
      {isPrime &&
        groupedPrimeDocuments.map((subcontractor, idx) => (
          <DocSection
            key={idx}
            subcontractor={subcontractor}
            onRequestDelete={onRequestDelete}
            idLoading={idLoading}
            canDelete={canDelete}
          />
        ))}
      {confirmModalInfo && (
        <ConfirmDialog
          title={confirmModalInfo.title}
          description={confirmModalInfo.description}
          onConfirm={confirmModalInfo.onConfirm}
          onCancel={closeConfirmModal}
        />
      )}
      <AddDocumentModal
        isOpen={!!addModalInfo}
        onAddDocument={onAddDocument}
        onClose={closeAddModal}
        documentType={addModalInfo?.documentType}
        saveUrl={
          inspectionId
            ? apiInternalInspectionDocumentsPath(undefined, { inspection_id: inspectionId })
            : apiInternalWorkOrderDocumentsPath(undefined, { work_order_id: workOrderId })
        }
      />
    </>
  );
}

export default DocumentsList;

DocumentsList.propTypes = {
  isPrime: PropTypes.bool,
  workOrderId: PropTypes.number,
  inspectionId: PropTypes.number,
  canDelete: PropTypes.bool,
  canCreate: PropTypes.bool
};

DocumentsList.defaultProps = {
  isPrime: false,
  workOrderId: undefined,
  inspectionId: undefined,
  canDelete: false,
  canCreate: false
};
