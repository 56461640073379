import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import { Dialog } from '@progress/kendo-react-dialogs';

function ConfirmDialog({
  title,
  description,
  onConfirm,
  onCancel,
  noLabel = translate('cancel', { namespace: 'features.commons' }),
  yesLabel = translate('ok', { namespace: 'features.commons' })
}) {
  return (
    <Dialog title={title} className="qmb-dialog" onClose={onCancel}>
      <p>{description}</p>
      <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
        <button type="button" className="qmb-button" onClick={onCancel}>
          {noLabel}
        </button>
        <button onClick={onConfirm} type="submit" className="qmb-button--submit">
          {yesLabel}
        </button>
      </div>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  noLabel: PropTypes.string,
  yesLabel: PropTypes.string
};

ConfirmDialog.defaultProps = {
  noLabel: translate('cancel', { namespace: 'features.commons' }),
  yesLabel: translate('ok', { namespace: 'features.commons' })
};

export default ConfirmDialog;
