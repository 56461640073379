import produce from 'immer';

import {
  actionTypes as dateRangePickerActionTypes,
  reducerValuesFunction as dateRangePickerReducerFunction
} from '@components/Kendo/DateRangePickerReducer';

export const actionTypes = {
  PAGE_CHANGED: 'PAGE_CHANGED',
  FILTER_CHANGED: 'FILTER_CHANGED',
  SORT_CHANGED: 'SORT_CHANGED',
  COLUMNS_CHANGED: 'COLUMNS_CHANGED',
  DATA_LOADED: 'DATA_LOADED',
  SELECTION_CHANGED: 'SELECTION_CHANGED',
  HEADER_SELECTION_CHANGED: 'HEADER_SELECTION_CHANGED',
  ALL_SELECTED: 'ALL_SELECTED',
  LOADING_CHANGED: 'LOADING_CHANGED',
  DATA_CHANGED: 'DATA_CHANGED',
  ...dateRangePickerActionTypes // static line, should be the only one for all DateRangePickers
};

export const reducer = produce((draft, action) => {
  // static snippet, should be the only one for all DateRangePickers
  if (Object.keys(dateRangePickerActionTypes).includes(action.type)) {
    dateRangePickerReducerFunction(draft, action);
    draft.selectAll = false;
    draft.page.skip = null;
    draft.page.skip = 0;
    return;
  }

  switch (action.type) {
    case actionTypes.PAGE_CHANGED:
      draft.page = action.page;
      break;
    case actionTypes.FILTER_CHANGED:
      if (action.filters) {
        draft.filters = action.filters;
      } else {
        draft.filters.values[action.field] = action.value;
      }
      draft.page.skip = null;
      draft.page.skip = 0;
      break;
    case actionTypes.FILTER_TOGGLED:
      draft.filters.active[action.field] = !draft.filters.active[action.field];
      draft.filters.values[action.field] = '';
      draft.page.skip = null;
      draft.page.skip = 0;
      break;
    case actionTypes.SORT_CHANGED:
      draft.sort = action.sort;
      draft.page.skip = null;
      draft.page.skip = 0;
      break;
    case actionTypes.COLUMNS_CHANGED:
      draft.columns = action.columns;
      break;
    case actionTypes.DATA_LOADED:
      draft.data = action.data.data;
      draft.totalCount = action.data.total_count;
      break;
    case actionTypes.SELECTION_CHANGED: {
      draft.selectAll = false;
      const item = draft.data.find((itemElem) => itemElem.id === action.id);
      item.selected = !item.selected;
      break;
    }
    case actionTypes.HEADER_SELECTION_CHANGED:
      if (!action.checked) draft.selectAll = false;
      draft.data.forEach((item) => {
        item.selected = action.checked;
      });
      break;
    case actionTypes.ALL_SELECTED:
      draft.selectAll = true;
      draft.data.forEach((item) => {
        item.selected = true;
      });
      break;
    case actionTypes.LOADING_CHANGED:
      draft.loading = action.loading;
      break;
    case actionTypes.DATA_CHANGED:
      draft.selectAll = false;
      draft.page.skip = null;
      draft.page.skip = 0;
      break;
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
