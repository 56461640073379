import React from "react";
import { translate } from '@utils/i18n';
function TimeOffDetails({ timeOff }) {
    var _a;
    const namespace = 'features.modals.time_off_modal.details';
    const format = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    const TechnicianInfo = ({ technician }) => {
        if (!technician) {
            return (React.createElement("span", { className: "option__tech--unassigned" }, React.createElement("i", { className: "fa-light fa-user-helmet-safety" }), " Unassigned"));
        }
        if (!technician.photoUrl) {
            return (React.createElement(React.Fragment, null, React.createElement("span", { className: "qmb-avatar--16" }, React.createElement("svg", { viewBox: "0 0 16 16" }, React.createElement("circle", { cx: "8", cy: "8", r: "8", style: { fill: technician.color } }), React.createElement("text", { x: "50%", y: "55%", dominantBaseline: "middle", textAnchor: "middle" }, technician.name[0]))), technician.name));
        }
        return (React.createElement("span", { className: "option__tech" }, React.createElement("span", { className: "qmb-avatar--16" }, React.createElement("img", { alt: technician.name[0], style: { borderRadius: '50%', border: `1px solid ${technician.color}`, boxSizing: 'border-box' }, src: technician.photoUrl })), technician.name));
    };
    return (React.createElement("div", { className: "dialog__section" }, React.createElement("ul", { className: "dialog__list--scheduling" }, React.createElement(React.Fragment, null, React.createElement("li", { className: "dialog__item" }, React.createElement(TechnicianInfo, { technician: timeOff.technician })), React.createElement("li", { className: "dialog__item" }, translate('type', { namespace }), ": ", (_a = timeOff.type) === null || _a === void 0 ? void 0 :
        _a.name)), React.createElement("li", { className: "dialog__item" }, timeOff.start.toLocaleString(undefined, format), " - ", timeOff.end.toLocaleString(undefined, format)), React.createElement("li", { className: "dialog__item" }, translate('notes', { namespace }), ": ", timeOff.notes))));
}
export default TimeOffDetails;
