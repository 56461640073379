import { useMemo, useContext } from 'react';
import { CalendarContext } from '../CalendarContext';

export default function useEvents() {
  const {
    inspectionStatusesForSelect,
    visitStatusesForSelect,
    state: { events, unselectedTechs, statuses, technicians }
  } = useContext(CalendarContext);

  const selectedStatusIds = useMemo(() => Object.keys(statuses).filter((key) => statuses[key]), [statuses]);

  const statusesForSelect = (type) => {
    if (type === 'Visit') return visitStatusesForSelect;

    return inspectionStatusesForSelect;
  };

  const findStatus = (event) => statusesForSelect(event.type).find((status) => status.value === event.status);

  const findTech = (event) => technicians.find((tech) => event.technicianId == tech.id);

  const findColor = (event, status, technician) => {
    if (event.type === 'TimeOff') return event.color;

    if (selectedStatusIds.includes(event.groupStatus)) return `${status.color}31`;

    return technician?.color || '#fff';
  };

  const filteredEvents = useMemo(() => {
    return events
      .filter((event) => event.type === 'TimeOff' || !unselectedTechs[event.technicianId])
      .map((event) => {
        const statusObject = findStatus(event);
        const technician = findTech(event);
        const color = findColor(event, statusObject, technician);

        return {
          ...event,
          backgroundColor: color,
          borderColor: color,
          technician,
          statusObject
        };
      });
  }, [unselectedTechs, statuses, events]);

  return filteredEvents;
}
