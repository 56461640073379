import React from 'react';
import { isValidDate } from '@fullcalendar/core/internal';
import { translate } from '@utils/i18n';

const namespace = 'features.dashboards.home.tables';

const InvoiceColumns = [
  {
    dataKey: 'id',
    width: 240,
    label: `${translate('invoice', { namespace })} #`,
    renderer: ({ item }) => <a href={`/invoices/${item?.id}`}>{item?.invoiceNo}</a>
  },
  {
    dataKey: 'invoiceableNumber',
    width: 165,
    label: translate('invoice_for', { namespace }),
    renderer: ({ item }) => <span>{item?.invoiceableId ? `Inspection #${item?.invoiceableId}` : 'N/A'}</span>
  },
  {
    dataKey: 'building',
    width: 290,
    label: translate('building', { namespace })
  },
  {
    dataKey: 'issueDate',
    label: translate('issue_date', { namespace }),
    renderer: ({ item }) => {
      const issueDate = new Date(item?.issueDate);
      if (isValidDate(issueDate)) {
        return issueDate.toLocaleDateString();
      }
      return 'N/A';
    }
  },
  {
    dataKey: 'paidDate',
    label: translate('paid_date', { namespace }),
    renderer: ({ item }) => {
      const paidDate = new Date(item?.paidDate);
      if (isValidDate(paidDate)) {
        return paidDate.toLocaleDateString();
      }
      return 'N/A';
    }
  },
  {
    dataKey: 'amount',
    label: translate('amount', { namespace }),
    renderer: ({ item }) => {
      if (!item?.sumAmount) return 'N/A';
      const roundedValue = Math.ceil(item.sumAmount * 100) / 100;
      return roundedValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  }
];

export default InvoiceColumns;
