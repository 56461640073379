import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stopDragging, types, status as DragStatus } from '../Redux/draggingSlice';
import ShallowEvent from './ShallowEvent';
import ShallowTechnician from './ShallowTechnician';
import ShallowVisit from './ShallowVisit';

const DragItem = React.forwardRef((_, boxRef) => {
  const type = useSelector((state) => state.dragging.type);
  const data = useSelector((state) => state.dragging.data);
  const isDragging = useSelector((state) => state.dragging.status) === DragStatus.DRAGGING;

  const element = useRef(null);
  const dispatch = useDispatch();

  const HandleMouseMove = (e) => {
    element.current.style.left = `${e.clientX - (boxRef?.current?.getBoundingClientRect().left ?? 0) - 25}px`;
    element.current.style.top = `${e.clientY - (boxRef?.current?.getBoundingClientRect().top ?? 0) - 5}px`;
  };

  const HandleMouseUp = () => {
    dispatch(stopDragging());
  };

  useEffect(() => {
    document.addEventListener('mousemove', HandleMouseMove);
    document.addEventListener('mouseup', HandleMouseUp);

    return () => {
      document.removeEventListener('mousemove', HandleMouseMove);
      document.removeEventListener('mouseup', HandleMouseUp);
    };
  }, [dispatch, isDragging]);

  const contents = () => {
    switch (type) {
      case types.EVENT:
        return <ShallowEvent event={data} />;
      case types.TECHNICIAN:
        return <ShallowTechnician technician={data} />;
      case types.VISIT:
        return <ShallowVisit visit={data} />;
      default:
        return <div style={{ background: 'red' }}>No Content</div>;
    }
  };

  return (
    <div
      className={isDragging ? 'workspace__draggable' : ''}
      ref={element}
      style={{
        pointerEvents: 'none',
        display: isDragging ? 'block' : 'none'
      }}>
      {contents()}
    </div>
  );
});

export default DragItem;

DragItem.displayName = 'DragItem';
