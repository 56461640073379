import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import I18n, { translate } from '@utils/i18n';
import { updateStatusesDeficienciesPath } from 'routes';
import ColumnMenuContext from './ColumnMenuContext';

const namespace = 'features.deficiencies_index.update_modal';

function UpdateModal({ deficiencyIds, disabled, queryParams, deficienciesCount }) {
  const { deficiencyStatusesForSelect, resolutionStatusesForSelect, afterSubmit } = useContext(ColumnMenuContext);

  const defaultResolved = { label: translate('select_resolved', { namespace }), value: null };
  const defaultDeficiencyStatus = { label: translate('select_status', { namespace }), value: null };
  const defaultResolutionStatus = { label: translate('select_resolution_status', { namespace }), value: null };

  const [visible, setVisible] = useState(false);

  const [isResolved, setIsResolved] = useState(defaultResolved);
  const [deficiencyStatus, setDeficiencyStatus] = useState(defaultDeficiencyStatus);
  const [resolutionStatus, setResolutionStatus] = useState(defaultResolutionStatus);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const clearForm = () => {
    toggleDialog();
    setIsResolved(defaultResolved);
    setDeficiencyStatus(defaultDeficiencyStatus);
    setResolutionStatus(defaultResolutionStatus);
  };

  const onSubmit = () => {
    const formData = {
      is_resolved: isResolved.value && '1',
      deficiency_resolution_status_id: resolutionStatus.value,
      deficiency_status: deficiencyStatus.value
    };

    Object.keys(formData).forEach((key) => {
      if (formData[key] === null) {
        delete formData[key];
      }
    });

    if (!deficiencyIds) {
      formData.all = true;
    } else {
      formData.deficiency_status_update_ids = deficiencyIds;
    }

    api.post(`${updateStatusesDeficienciesPath()}?${queryParams}`, formData).then(() => afterSubmit());
  };

  return (
    <>
      <button type="button" tabIndex="0" className="qmb-control" disabled={disabled} onClick={toggleDialog}>
        <i className="fa-light fa-pen-to-square" />
        {translate('button', { namespace })}
      </button>
      {visible && (
        <Dialog title={translate('title', { namespace })} className="qmb-dialog" onClose={toggleDialog}>
          <p>{translate('subtitle', { namespace, count: deficienciesCount })}</p>
          <table className="qmb-table--spreadsheet">
            <thead>
              <tr>
                <th>{translate('change', { namespace })}</th>
                <th>{translate('value', { namespace })}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="nested_fields">
                <td className="table__cell">
                  <div className="qmb-input--text">
                    <input value={translate('resolved', { namespace })} disabled />
                  </div>
                </td>
                <td className="table__cell">
                  <div className="qmb-select--blank">
                    <DropDownList
                      name="resolved"
                      textField="label"
                      dataItemKey="value"
                      data={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false }
                      ]}
                      value={isResolved}
                      defaultItem={defaultResolved}
                      onChange={(event) => setIsResolved(event.target.value)}
                      size="large"
                    />
                  </div>
                </td>
              </tr>
              <tr className="nested_fields">
                <td className="table__cell">
                  <div className="qmb-input--text">
                    <input value={translate('resolution_status', { namespace })} disabled />
                  </div>
                </td>
                <td className="table__cell">
                  <div className="qmb-select--blank">
                    <DropDownList
                      name="resolutionStatus"
                      textField="label"
                      dataItemKey="value"
                      data={resolutionStatusesForSelect}
                      value={resolutionStatus}
                      defaultItem={defaultResolutionStatus}
                      onChange={(event) => setResolutionStatus(event.target.value)}
                      size="large"
                    />
                  </div>
                </td>
              </tr>
              <tr className="nested_fields">
                <td className="table__cell">
                  <div className="qmb-input--text">
                    <input value={translate('status', { namespace })} disabled />
                  </div>
                </td>
                <td className="table__cell">
                  <div className="qmb-select--blank">
                    <DropDownList
                      name="deficiencyStatus"
                      textField="label"
                      dataItemKey="value"
                      data={deficiencyStatusesForSelect}
                      value={deficiencyStatus}
                      defaultItem={defaultDeficiencyStatus}
                      onChange={(event) => setDeficiencyStatus(event.target.value)}
                      size="large"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <div className="mt-3">
              <button type="button" className="qmb-button" onClick={clearForm}>
                {I18n.t('generic.cancel')}
              </button>
              <button type="button" onClick={onSubmit} className="qmb-button--submit">
                {I18n.t('generic.update')}
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}

UpdateModal.propTypes = {
  deficiencyIds: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool.isRequired,
  queryParams: PropTypes.object.isRequired
};

UpdateModal.defaultProps = {
  deficiencyIds: null
};

export default UpdateModal;
