import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import {
  inspectionsDashboardPath,
  workOrdersDashboardPath,
  deficienciesDashboardPath,
  proposalsDashboardPath,
  paymentsDashboardPath,
  invoicesDashboardPath,
  highPriorityDashboardPath
} from 'routes';
import { DateTime } from 'luxon';
import { ActionTypes, reducer } from './reducer';
import { BaseStatusesSelectionSort, DashboardEntityType, InitialState } from './constants';
import { Cache, CacheKeys } from '../../../../utils/Cache';
import getSortedStatuses from '../utils/getSortedStatuses';
import { BaseOtherStatus } from '../nested/StatusCounterWidget/constants';

export const HomeDataTableContext = React.createContext({
  state: {
    entityType: null,
    tableData: [],
    isModalOpen: false,
    columnsStructureData: [],
    status: null,
    inspectionStatuses: [],
    workOrderStatuses: [],
    statusesSelection: {
      inspections: [],
      workOrders: []
    }
  },
  actions: {
    loadInspections: () => {},
    closeModal: () => {},
    changeStatusesSelection: () => {},
    loadWorkOrders: () => {},
    loadInvoices: () => {},
    loadPayments: () => {},
    loadProposals: () => {},
    loadDeficiencies: () => {},
    loadHighPriorities: () => {}
  }
});

export function HomeDataTableProvider({ inspectionStatuses, workOrderStatuses, children }) {
  const [state, dispatch] = React.useReducer(reducer, {
    ...InitialState.state,
    inspectionStatuses: getSortedStatuses(inspectionStatuses, 'inspections'),
    workOrderStatuses: getSortedStatuses(workOrderStatuses, 'workOrders')
  });

  const loadInspections = async ({ status, isToday }) => {
    dispatch({
      type: ActionTypes.START_LOADING,
      payload: { entityType: DashboardEntityType.Inspections, statusSelected: status, isToday }
    });
    const params = {
      status:
        status === BaseOtherStatus.statusCode
          ? inspectionStatuses
              .filter((s) => !state.statusesSelection.inspections.includes(s.statusCode))
              .map((s) => s.statusCode)
          : status,
      start_date: isToday ? DateTime.local().startOf('day').toISODate() : DateTime.local().startOf('month').toISODate(),
      end_date: isToday ? DateTime.local().endOf('day').toISODate() : DateTime.local().endOf('month').toISODate()
    };

    const { data: res } = await api.get(inspectionsDashboardPath({ format: 'json' }), { params });
    dispatch({
      type: ActionTypes.LOAD_DATA,
      payload: { entityType: DashboardEntityType.Inspections, tableData: res }
    });
  };

  const loadWorkOrders = async ({ status, isToday, allVisits }) => {
    dispatch({
      type: ActionTypes.START_LOADING,
      payload: { entityType: DashboardEntityType.WorkOrders, statusSelected: status, isToday }
    });
    const params = {
      status:
        status === BaseOtherStatus.statusCode
          ? workOrderStatuses
              .filter((s) => !state.statusesSelection.workOrders.includes(s.statusCode))
              .map((s) => s.statusCode)
          : status,
      start_date: isToday ? DateTime.local().startOf('day').toISODate() : DateTime.local().startOf('month').toISODate(),
      end_date: isToday ? DateTime.local().endOf('day').toISODate() : DateTime.local().endOf('month').toISODate(),
      template: allVisits === true ? 'visits' : null
    };

    const { data: res } = await api.get(workOrdersDashboardPath({ format: 'json' }), { params });
    dispatch({
      type: ActionTypes.LOAD_DATA,
      payload: { entityType: DashboardEntityType.WorkOrders, tableData: res }
    });
  };

  const loadHighPriorities = async () => {
    dispatch({
      type: ActionTypes.START_LOADING,
      payload: { entityType: DashboardEntityType.HighPriorities }
    });

    const { data: res } = await api.get(highPriorityDashboardPath({ format: 'json' }));
    dispatch({
      type: ActionTypes.LOAD_DATA,
      payload: { entityType: DashboardEntityType.HighPriorities, tableData: res }
    });
  };

  const loadInvoices = async ({ status }) => {
    const entityType = status === 'paid' ? DashboardEntityType.PaidInvoices : DashboardEntityType.Invoices;
    dispatch({
      type: ActionTypes.START_LOADING,
      payload: { entityType, statusSelected: status }
    });

    const params = {
      status,
      start_date: DateTime.local().minus({ days: 30 }).toISODate(),
      end_date: DateTime.local().toISODate()
    };
    const { data: res } = await api.get(invoicesDashboardPath({ format: 'json' }), { params });
    dispatch({
      type: ActionTypes.LOAD_DATA,
      payload: { entityType, tableData: res }
    });
  };

  const loadPayments = async () => {
    dispatch({
      type: ActionTypes.START_LOADING,
      payload: { entityType: DashboardEntityType.Payments }
    });
    const params = {
      start_date: DateTime.local().minus({ days: 30 }).toISODate(),
      end_date: DateTime.local().toISODate()
    };
    const { data: res } = await api.get(paymentsDashboardPath({ format: 'json' }), { params });

    dispatch({
      type: ActionTypes.LOAD_DATA,
      payload: { entityType: DashboardEntityType.Payments, tableData: res }
    });
  };

  const loadProposals = async ({ status }) => {
    dispatch({
      type: ActionTypes.START_LOADING,
      payload: { entityType: DashboardEntityType.Proposals, statusSelected: status }
    });
    const params = {
      status,
      start_date: DateTime.local().minus({ days: 30 }).toISODate(),
      end_date: DateTime.local().toISODate()
    };
    const { data: res } = await api.get(proposalsDashboardPath({ format: 'json' }), { params });

    dispatch({
      type: ActionTypes.LOAD_DATA,
      payload: { entityType: DashboardEntityType.Proposals, tableData: res }
    });
  };

  const loadDeficiencies = async ({ status }) => {
    dispatch({
      type: ActionTypes.START_LOADING,
      payload: { entityType: DashboardEntityType.Deficiencies, statusSelected: status }
    });
    const params = {
      status,
      start_date: DateTime.local().minus({ days: 30 }).toISODate(),
      end_date: DateTime.local().toISODate()
    };
    const { data: res } = await api.get(deficienciesDashboardPath({ format: 'json' }), { params });

    dispatch({
      type: ActionTypes.LOAD_DATA,
      payload: { entityType: DashboardEntityType.Deficiencies, tableData: res }
    });
  };

  useEffect(() => {
    const statusesSelection = Cache.getItem(CacheKeys.StatusesSelection);
    if (statusesSelection) {
      dispatch({ type: ActionTypes.LOAD_STATUSES_SELECTION, payload: { statusesSelection } });
    } else {
      Cache.setItem(CacheKeys.StatusesSelection, BaseStatusesSelectionSort);
      changeStatusesSelection(BaseStatusesSelectionSort);
    }
  }, []);

  const closeModal = () => {
    dispatch({ type: ActionTypes.CLOSE_MODAL });
  };

  const changeStatusesSelection = async (statusesSelection) => {
    Cache.setItem(CacheKeys.StatusesSelection, statusesSelection);
    dispatch({ type: ActionTypes.LOAD_STATUSES_SELECTION, payload: { statusesSelection } });
  };

  const contextValue = useMemo(() => {
    return {
      state,
      actions: {
        loadInspections,
        loadHighPriorities,
        closeModal,
        changeStatusesSelection,
        loadWorkOrders,
        loadInvoices,
        loadPayments,
        loadProposals,
        loadDeficiencies
      }
    };
  }, [state, dispatch]);

  return <HomeDataTableContext.Provider value={contextValue}>{children}</HomeDataTableContext.Provider>;
}

HomeDataTableProvider.propTypes = {
  children: PropTypes.node,
  inspectionStatuses: PropTypes.array,
  workOrderStatuses: PropTypes.array
};
HomeDataTableProvider.defaultProps = {
  children: null,
  inspectionStatuses: [],
  workOrderStatuses: []
};
