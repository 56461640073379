import { loadCacheFromLocalStorage, toRestoreGridPage } from '../../../helpers/localStorage';
import { INITIAL_SORT, ALL_COLUMNS, INITIAL_FILTERS, INITIAL_PAGE } from '../constants';

export const getInitialData = ({ cacheKey, screen, screenId }) => {
  const cache = loadCacheFromLocalStorage(cacheKey);
  if (cacheIsValid(cache, cacheKey)) {
    return {
      ...cache,
      page: toRestoreGridPage() && cache.page ? cache.page : INITIAL_PAGE,
      data: [],
      totalCount: 0,
      selectAll: false,
      loading: true
    };
  }

  return {
    page: INITIAL_PAGE,
    sort: INITIAL_SORT,
    filters: getInitialFilters(screen, screenId),
    columns: getInitialColumns(screen),
    data: [],
    totalCount: 0,
    selectAll: false,
    loading: true
  };
};

export const getInitialFilters = (screen, screenId) => {
  if (!screen) return INITIAL_FILTERS;

  return { ...INITIAL_FILTERS, values: { ...INITIAL_FILTERS.values, [screen]: screenId } };
};

const getInitialColumns = (screen) => {
  if (screen === 'inspection') {
    return [
      'selected',
      'id',
      'dateOpened',
      'resolved',
      'systemAsset',
      'details',
      'deficiencyStatus',
      'notes',
      'actions'
    ];
  }

  if (screen === 'building' || screen === 'workOrder') {
    return [
      'selected',
      'id',
      'inspection',
      'dateOpened',
      'resolved',
      'systemAsset',
      'details',
      'deficiencyStatus',
      'notes',
      'actions'
    ];
  }

  return [
    'selected',
    'id',
    'inspection',
    'building',
    'dateOpened',
    'resolved',
    'systemAsset',
    'details',
    'deficiencyStatus',
    'notes',
    'actions'
  ];
};

const cacheIsValid = (cache, cacheKey) => {
  if (!cache) return false;

  if (
    cache.filters &&
    cache.filters.values &&
    cache.filters.active &&
    cache.filters.operators &&
    cache.sort &&
    cache.columns &&
    cache.columns.every((column) => ALL_COLUMNS.includes(column))
  )
    return true;

  localStorage.removeItem(cacheKey);
  return false;
};
