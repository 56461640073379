import { useEffect, useState } from 'react';
const useDataAccumulator = (newData) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (newData)
            setData((prevState) => [...prevState, ...newData]);
    }, [newData]);
    return { data };
};
export default useDataAccumulator;
