import { useCallback, useRef } from 'react';

export default function useVirtualizedList() {
  const sizeMap = useRef({});
  const listRef = useRef();

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getSize = (index) => sizeMap.current[index] || 50;

  return {
    listRef,
    setSize,
    getSize
  };
}
