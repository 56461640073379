import React, { useEffect, useMemo, useState } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import api from 'axios';
import { isInspectionPrimaryVisit, isInspectionVisit, isServiceVisit } from '@utils/typeGuards/polymorphic-visit.typeguard';
import { translate } from '@utils/i18n';
import useUserSelectionLock from '@hooks/user-selection-lock.hook';
import Details from './nested/details';
import Form from './nested/form';
import { Loader, LoaderSizes } from '../../../components/Loader/Loader';
import { apiInternalInspectionPrimaryVisitPath, apiInternalInspectionVisitPath, apiInternalServiceVisitPath, inspectionPath, workOrderPath } from '../../../routes';
function VisitEditModal(params) {
    const namespace = 'features.modals.visit_modal';
    const [visit, setVisit] = useState();
    const [formReady, setFormReady] = useState(false);
    const { userSelectionLocked, setUserSelectionLock } = useUserSelectionLock();
    const handleVisitSaved = (visit) => {
        setVisit(visit);
        params.onSave(visit);
    };
    const ModalContent = useMemo(() => {
        if (visit)
            return (React.createElement(React.Fragment, null, React.createElement("div", { style: { display: formReady ? 'block' : 'none' } }, React.createElement(Details, { visit: visit }), React.createElement("hr", { className: "dialog__split" }), React.createElement(Form, { visit: visit, readonly: false, onSave: handleVisitSaved, onCancel: params.onClose, onLoad: () => {
                    setFormReady(true);
                } })), !formReady && (React.createElement("div", { style: { height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, React.createElement(Loader, { size: LoaderSizes.large })))));
        return (React.createElement("div", { style: { height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, React.createElement(Loader, { size: LoaderSizes.large })));
    }, [visit, formReady]);
    useEffect(() => {
        document.querySelector('.k-overlay').addEventListener('click', params.onClose);
        let url;
        switch (params.type) {
            case "Visit" /* VisitType.SERVICE_VISIT */:
                url = apiInternalServiceVisitPath(params.id);
                break;
            case "InspectionVisit" /* VisitType.INSPECTION_VISIT */:
                url = apiInternalInspectionVisitPath(params.id);
                break;
            case "Inspection" /* VisitType.INSPECTION_PRIMARY_VISIT */:
                url = apiInternalInspectionPrimaryVisitPath(params.id);
                break;
        }
        api
            .get(url)
            .then((response) => {
            const result = response.data.service_visit || response.data.inspection_visit || response.data.primary_visit;
            if (result) {
                const tmpVisit = Object.assign(Object.assign({}, result), { type: params.type, start: new Date(result.start), end: new Date(result.end) });
                setVisit(tmpVisit);
            }
        })
            .catch((error) => {
            console.error(error);
            setVisit(undefined);
        });
    }, []);
    const header = useMemo(() => {
        const style = { width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' };
        let icon = null;
        let title = null;
        let link = null;
        if (!formReady) {
            icon = React.createElement(Loader, { size: LoaderSizes.small });
            title = translate('loading', { namespace });
            link = null;
        }
        else {
            title = visit.title;
            if (isServiceVisit(visit)) {
                icon = React.createElement("i", { className: "fa-light fa-screwdriver-wrench" });
                link = workOrderPath(visit.workOrderId);
            }
            else if (isInspectionVisit(visit)) {
                icon = React.createElement("i", { className: "fa-light fa-clipboard-check" });
                link = inspectionPath(visit.inspectionId);
            }
            else if (isInspectionPrimaryVisit(visit)) {
                icon = React.createElement("i", { className: "fa-light fa-clipboard-check" });
                link = inspectionPath(visit.id);
            }
        }
        return (React.createElement("div", { style: style }, React.createElement("span", { className: "dialog__faux-title" }, icon, " ", title), link && (React.createElement("a", { href: link, target: "_blank", className: "qmb-control--sm", rel: "noreferrer" }, translate('details_link', { namespace }), React.createElement("i", { className: "fa-light fa-arrow-up-right-from-square" })))));
    }, [visit, formReady]);
    return (React.createElement(Window, { modal: true, onClose: params.onClose, title: header, minWidth: 240, initialWidth: 480, initialHeight: 640, minHeight: 640, minimizeButton: () => null, maximizeButton: () => null, restoreButton: () => null, className: "qmb-dialog", style: { userSelect: userSelectionLocked ? 'none' : 'auto' }, onResize: (e) => setUserSelectionLock(!e.end) }, ModalContent));
}
export default VisitEditModal;
