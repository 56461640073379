import React, { useRef, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { translate } from '@utils/i18n';
import { CalendarContext } from '../../CalendarContext';

const namespace = 'features.calendar.header.technicians';

function Technicians() {
  const {
    state: { technicians, unselectedTechs },
    actions: { toggleSelectAllTechs, toggleUnselectAllTechs, onToggleTech }
  } = useContext(CalendarContext);

  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpen] = useState(false);

  const handleBodyClick = useCallback((event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const onAddEventPopup = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const selectedTechs = useMemo(
    () => technicians.filter((tech) => !unselectedTechs[tech.id]),
    [unselectedTechs, technicians]
  );
  const unselectedTechsLength = useMemo(
    () => Object.keys(unselectedTechs).filter((tech) => unselectedTechs[tech]).length,
    [unselectedTechs]
  );

  return (
    <>
      <button className="qmb-control--menu" type="button" ref={anchor} onClick={onAddEventPopup}>
        {translate('title', { namespace })}
        {selectedTechs.length > 0 && (
          <span className="control__collection">
            {selectedTechs.slice(0, 3).map((technician) => (
              <TechIcon technician={technician} key={technician.id} />
            ))}

            {selectedTechs.length > 3 && <span className="control__count">&#43;{selectedTechs.length - 3}</span>}
          </span>
        )}
      </button>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--actions--y-max">
          <div className="popup__section--y-fill">
            <ul role="presentation">
              {technicians.map((technician) => (
                <li key={technician.id}>
                  <label className="qmb-checkbox">
                    <input
                      type="checkbox"
                      onChange={onToggleTech(technician.id)}
                      checked={!unselectedTechs[technician.id]}
                    />
                    <TechIcon technician={technician} />
                    {technician.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <hr className="popup__split" />
          {unselectedTechsLength > 0 && (
            <div className="popup__section">
              <button type="button" className="qmb-control" onClick={toggleSelectAllTechs}>
                {translate('select_all', { namespace })}
              </button>
            </div>
          )}
          {unselectedTechsLength === 0 && (
            <div className="popup__section">
              <button type="button" className="qmb-control" onClick={toggleUnselectAllTechs}>
                {translate('unselect_all', { namespace })}
              </button>
            </div>
          )}
        </div>
      </Popup>
    </>
  );
}

function TechIcon({ technician }) {
  if (!technician?.id)
    return (
      <span className="qmb-avatar--24--unassigned">
        <i className="fa-light fa-user-helmet-safety" />
      </span>
    );

  if (!technician.photoUrl) {
    return (
      <span className="qmb-avatar--24">
        <svg viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
          <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
            {technician.name[0]}
          </text>
        </svg>
      </span>
    );
  }
  return (
    <span className="qmb-avatar--24">
      <img
        alt="technician"
        style={{ borderRadius: '50%', border: `2px solid ${technician.color}`, boxSizing: 'border-box' }}
        src={technician.photoUrl}
      />
    </span>
  );
}

TechIcon.propTypes = {
  technician: PropTypes.shape({
    id: PropTypes.number,
    photoUrl: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default Technicians;
