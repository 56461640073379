import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const namespace = 'features.deficiencies_index.actions_cell';

function PhotosCell({ colSpan, dataItem, className, ariaColumnIndex, style }) {
  const [photosDialogVisible, setPhotosDialogVisible] = useState(false);
  const photos = useMemo(() => dataItem.photos.map((photo) => ({ src: photo.large, width: null, height: null })), []);
  const thumbs = useMemo(() => dataItem.photos.map((photo) => ({ src: photo.thumb, width: null, height: null })), []);
  const [photoIndex, setPhotoIndex] = useState(-1);

  if (dataItem.photos.length > 0)
    return (
      <>
        <td colSpan={colSpan} className={className} aria-colindex={ariaColumnIndex} style={style}>
          <button type="button" className="qmb-control" onClick={() => setPhotosDialogVisible(true)}>
            {dataItem.photos.length}
          </button>
        </td>
        {photosDialogVisible && (
          <Dialog
            title={translate('deficiency_photos', { namespace })}
            className="qmb-dialog"
            onClose={() => setPhotosDialogVisible(false)}>
            <Gallery images={thumbs} enableImageSelection={false} onClick={(number) => setPhotoIndex(number)} />
            <Lightbox open={photoIndex >= 0} index={photoIndex} close={() => setPhotoIndex(-1)} slides={photos} />
          </Dialog>
        )}
      </>
    );
  return (
    <td colSpan={colSpan} className={className} aria-colindex={ariaColumnIndex} style={style}>
      <button type="button" className="qmb-control">
        0
      </button>
    </td>
  );
}

PhotosCell.propTypes = {
  ariaColumnIndex: PropTypes.number.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  colSpan: PropTypes.number.isRequired,
  dataItem: PropTypes.shape({
    isResolved: PropTypes.bool,
    photos: PropTypes.arrayOf(PropTypes.object),
    path: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

PhotosCell.defaultProps = {
  style: null,
  className: ''
};

export default PhotosCell;
