import { useMemo, useContext } from 'react';
import { translate } from '@utils/i18n';
import { CalendarContext } from '../CalendarContext';

const namespace = 'features.calendar.header.index';

export default function useFilters() {
  const {
    assetsForSelect,
    territoriesForSelect,
    inspectionTypesForSelect,
    inspectionStatusesForSelect,
    visitStatusesForSelect,
    permissions,
    actions: { onChangeSearchFilter, onChangeReactFilter, onToggleFilter, onTypeFilterChange, onTypeFilterToggle },
    state: {
      filters: { active: filterActiveState, values: filterValuesState }
    }
  } = useContext(CalendarContext);

  const filtersList = () => {
    return [
      {
        field: 'asset',
        locale: 'asset',
        type: 'treeSelect',
        active: filterActiveState.asset,
        value: filterValuesState.asset,
        optionsForSelect: assetsForSelect,
        onChange: onChangeReactFilter('asset'),
        onToggle: onToggleFilter('asset')
      },
      {
        field: 'building',
        locale: 'building',
        type: 'searchSelect',
        active: filterActiveState.building,
        locked: false,
        value: filterValuesState.building,
        onChange: onChangeSearchFilter('building'),
        onToggle: onToggleFilter('building')
      },
      {
        field: 'technicianTeam',
        locale: 'technician_team',
        type: 'searchSelect',
        active: filterActiveState.technicianTeam,
        locked: false,
        value: filterValuesState.technicianTeam,
        onChange: onChangeSearchFilter('technicianTeam'),
        onToggle: onToggleFilter('technicianTeam')
      },
      {
        field: 'territory',
        locale: 'territory',
        type: 'commonSelect',
        active: filterActiveState.territory,
        locked: false,
        optionsForSelect: territoriesForSelect,
        value: filterValuesState.territory,
        onChange: onChangeReactFilter('territory'),
        onToggle: onToggleFilter('territory')
      },
      {
        field: 'tags',
        locale: 'tags',
        type: 'reactMultiSelect',
        active: filterActiveState.tags,
        locked: false,
        value: filterValuesState.tags,
        onChange: onChangeReactFilter('tags'),
        onToggle: onToggleFilter('tags')
      }
    ].concat(visitTypeFilters());
  };

  const visitTypeFilters = () => {
    if (permissions.hasService)
      return [
        {
          field: 'type',
          locale: 'visit_type',
          type: 'commonSelect',
          active: filterActiveState.type,
          locked: false,
          optionsForSelect: [
            { value: 'Visit', label: 'Work Order' },
            { value: 'Inspection', label: 'Inspection' }
          ],
          value: filterValuesState.type,
          onChange: onTypeFilterChange(),
          onToggle: onTypeFilterToggle()
        },
        {
          field: 'inspectionStatus',
          locale: 'inspection_status',
          type: 'searchSelect',
          backendField: 'status_code',
          placeholder: 'filters.all_except_cancelled',
          active: filterValuesState.type === 'Inspection',
          locked: true,
          optionsForSelect: inspectionStatusesForSelect,
          value: filterValuesState.inspectionStatus,
          template: (dataItem) => inspectionStatusTemplate(dataItem),
          valueTemplate: (dataItem) => inspectionStatusValueTemplate(dataItem),
          onChange: onChangeSearchFilter('inspectionStatus'),
          onToggle: onToggleFilter('inspectionStatus'),
          itemHeight: 40,
          tooltip:
            filterValuesState.type === 'Inspection' ? undefined : translate('inspection_status_tooltip', { namespace })
        },
        {
          field: 'inspectionType',
          locale: 'inspection_type',
          type: 'commonSelect',
          active: filterValuesState.type === 'Inspection',
          locked: true,
          optionsForSelect: inspectionTypesForSelect,
          value: filterValuesState.inspectionType,
          onChange: onChangeReactFilter('inspectionType'),
          onToggle: onToggleFilter('inspectionType'),
          tooltip:
            filterValuesState.type === 'Inspection' ? undefined : translate('inspection_type_tooltip', { namespace })
        },
        {
          field: 'visitStatus',
          locale: 'visit_status',
          type: 'commonSelect',
          placeholder: 'filters.all_except_cancelled',
          active: filterValuesState.type === 'Visit',
          locked: true,
          optionsForSelect: visitStatusesForSelect,
          value: filterValuesState.visitStatus,
          onChange: onChangeReactFilter('visitStatus'),
          onToggle: onToggleFilter('visitStatus'),
          tooltip: filterValuesState.type === 'Visit' ? undefined : translate('work_order_tooltip', { namespace })
        }
      ];
    return [
      {
        field: 'inspectionStatus',
        locale: 'inspection_status',
        type: 'searchSelect',
        backendField: 'status_code',
        placeholder: 'filters.all_except_cancelled',
        active: filterActiveState.inspectionStatus,
        optionsForSelect: inspectionStatusesForSelect,
        value: filterValuesState.inspectionStatus,
        template: (dataItem) => inspectionStatusTemplate(dataItem),
        valueTemplate: (dataItem) => inspectionStatusValueTemplate(dataItem),
        onChange: onChangeSearchFilter('inspectionStatus'),
        onToggle: onToggleFilter('inspectionStatus'),
        itemHeight: 40
      },
      {
        field: 'inspectionType',
        locale: 'inspection_type',
        type: 'commonSelect',
        active: filterActiveState.inspectionType,
        optionsForSelect: inspectionTypesForSelect,
        value: filterValuesState.inspectionType,
        onChange: onChangeReactFilter('inspectionType'),
        onToggle: onToggleFilter('inspectionType')
      }
    ];
  };

  const inspectionStatusTemplate = ({ label, icon, color }) => {
    return `<div style="display: flex; align-items: center">
              <span class="qmb-avatar--24--status-icon" style="display: flex; border-radius: 50%; align-items: center; justify-content: center">
                <i class="fa-lg ${icon}" style="color: ${color}" />
              </span>
              <div style="margin-left: 0.8rem;">${label}</div>
            </div>`;
  };

  const inspectionStatusValueTemplate = ({ label, icon, color }) => {
    return `<span class="qmb-avatar--24--status-icon">
              <i class="fa-lg ${icon}" style="color: ${color}" />
            </span> ${label}`;
  };

  const filters = useMemo(() => filtersList(), [filterActiveState, filterValuesState]);

  return filters;
}
