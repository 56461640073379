import { useCallback, useState } from "react";
const useUserSelectionLock = () => {
    const [userSelectionLocked, setUserSelectionLocked] = useState(false);
    const setUserSelectionLock = useCallback((preventSelections) => {
        if (userSelectionLocked && !preventSelections)
            setUserSelectionLocked(false);
        if (!userSelectionLocked && preventSelections)
            setUserSelectionLocked(true);
    }, [userSelectionLocked]);
    return { userSelectionLocked, setUserSelectionLock };
};
export default useUserSelectionLock;
