import React from 'react';
import { translate } from '@utils/i18n';
import { isValidDate } from '@fullcalendar/core/internal';
import { getDateFormat } from '../../utils/getDatetimeFormat';
import { Loader, LoaderSizes } from '../../components/Loader/Loader';

const namespace = 'features.documents';

function getDocumentColumns({ onRequestDelete, idLoading, canDelete }) {
  return [
    {
      label: translate('document', { namespace }),
      dataKey: 'name',
      width: 240,
      renderer: ({ item }) => {
        return (
          <div className="doc-name-container">
            {item.type === 'Image' && <i className="fa-light fa-file-image" />}
            {item.type === 'Document' && <i className="fa-light fa-file-word" />}
            <a rel="noreferrer" className="qmb-link" href={item.url} target="_blank">
              {item.file_name}
            </a>
          </div>
        );
      }
    },
    {
      label: translate('document_type', { namespace }),
      align: 'left',
      dataKey: 'content_type',
      width: 416
    },
    {
      label: translate('uploaded', { namespace }),
      align: 'left',
      dataKey: 'createdAt',
      width: 416,
      renderer: ({ item }) => {
        const scheduledDate = new Date(item?.created_at);
        if (isValidDate(scheduledDate)) {
          return getDateFormat(item?.created_at);
        }
        return translate('n_a', { namespace: 'features.commons' });
      }
    },
    {
      label: '',
      align: 'left',
      dataKey: 'id',
      renderer: ({ item }) => {
        if (!canDelete) return null;
        return idLoading === item.id ? (
          <Loader size={LoaderSizes.small} />
        ) : (
          <button type="button" className="qmb-control--icon" onClick={onRequestDelete(item.id)}>
            <i className="fa-light fa-trash-can" />
          </button>
        );
      }
    }
  ];
}

export default getDocumentColumns;
