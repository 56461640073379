import api from '@utils/axios';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { translate } from '@utils/i18n';
import {
  apiInternalInspectionDocumentsPath,
  apiInternalInspectionDocumentPath,
  apiInternalInspectionDocumentTypesPath,
  apiInternalWorkOrderDocumentPath,
  apiInternalWorkOrderDocumentsPath,
  apiInternalWorkOrderDocumentTypesPath
} from '../../../routes';

const namespace = 'features.documents';

function useDocuments({ workOrderId, inspectionId, isPrime }) {
  const [documents, setDocuments] = useState([]);
  const [documentsType, setDocumentsType] = useState([]);
  const [confirmModalInfo, setConfirmModalInfo] = useState(null);
  const [addModalInfo, setAddModalInfo] = useState(null);
  const [idLoading, setIdLoading] = useState(null);

  const getDocuments = async () => {
    try {
      const getPath = inspectionId ? apiInternalInspectionDocumentsPath : apiInternalWorkOrderDocumentsPath;
      const { data } = await api.get(
        getPath(undefined, {
          work_order_id: workOrderId,
          inspection_id: inspectionId,
          format: 'json'
        })
      );
      setDocuments(data.documents);
    } catch (error) {
      toast.error(translate('load_document_error', { namespace }), {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const onRequestAdd = (documentType) => {
    setAddModalInfo({ documentType });
  };

  const closeAddModal = () => {
    setAddModalInfo(null);
  };

  const getDocumentsType = async () => {
    const getPath = inspectionId ? apiInternalInspectionDocumentTypesPath : apiInternalWorkOrderDocumentTypesPath;
    const { data } = await api.get(
      getPath(undefined, {
        work_order_id: workOrderId,
        inspection_id: inspectionId,
        format: 'json'
      })
    );
    setDocumentsType(data.types);
  };

  const onAddDocument = (res) => {
    setDocuments([...documents, ...res.documents]);
    closeAddModal();
  };

  useEffect(() => {
    getDocuments();
    getDocumentsType();
  }, []);

  useEffect(() => {
    if (documentsType.length === 0 || isPrime) return;
    const requiredTypes = documentsType.filter((type) => type.is_required);
    if (requiredTypes.every((type) => groupedDocuments[type.id].length > 0)) {
      document.getElementById('submitToPrimeBtn').removeAttribute('disabled');
    } else {
      document.getElementById('submitToPrimeBtn')?.setAttribute('disabled', 'disabled');
    }
  }, [documents, documentsType]);

  const groupedDocuments = useMemo(() => {
    return documentsType?.reduce((acc, type) => {
      acc[type.id] = documents.filter((document) => document.document_type_id === type.id);
      return acc;
    }, {});
  }, [documentsType, documents]);

  const groupedPrimeDocuments = useMemo(() => {
    const subs = documents
      .map((doc) => doc.uploader_tenant)
      .reduce((acc, cur) => (acc.some((ten) => ten?.id === cur?.id) ? acc : [...acc, cur]), []);

    return subs
      .map((sub) => {
        return {
          name: sub?.name || 'N/A',
          docs: documentsType
            .map((type) => {
              return {
                title: `${type.title} ${type.is_required ? '*' : ''}`,
                data: documents.filter(
                  (document) => document.document_type_id === type.id && document.uploader_tenant?.id === sub?.id
                )
              };
            }, {})
            .filter((type) => type.data.length > 0)
        };
      })
      .sort((a, b) => {
        if (a.name === 'N/A') return 1;
        if (b.name === 'N/A') return -1;
        return 0;
      });
  }, [documentsType, documents]);

  const onDeleteDocument = async (documentId) => {
    closeConfirmModal();
    try {
      setIdLoading(documentId);
      const deletePath = inspectionId ? apiInternalInspectionDocumentPath : apiInternalWorkOrderDocumentPath;
      await api.delete(deletePath(undefined, inspectionId ?? workOrderId, documentId));
      setDocuments([...documents.filter((doc) => doc.id !== documentId)]);
    } catch (error) {
      toast.error(translate('delete_document_error', { namespace }), {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setIdLoading(null);
    }
  };

  const onRequestDelete = (documentId) => async () => {
    setConfirmModalInfo({
      description: translate('confirm_delete_desc', { namespace }),
      title: translate('confirm_delete_title', { namespace }),
      onConfirm: () => onDeleteDocument(documentId)
    });
  };

  const closeConfirmModal = () => {
    setConfirmModalInfo(null);
  };

  return {
    addModalInfo,
    groupedDocuments,
    documentsType,
    idLoading,
    onAddDocument,
    onRequestDelete,
    closeConfirmModal,
    confirmModalInfo,
    onRequestAdd,
    closeAddModal,
    groupedPrimeDocuments
  };
}

export default useDocuments;
