import React, { useEffect } from 'react';
import { translate } from '@utils/i18n';
import useGetTechnicians from '@hooks/requests/technicians.get.hook';
import useApiQuery from '@hooks/api-params.hook';
import useDataAccumulator from '@hooks/data-accumulator.hook';
import TechnicianSortableAttributes from '@enums/sortable-attributes/technician-sortable-attributes.enum';
function TechnicianDropdown(params) {
    const namespace = 'components.technician_dropdown';
    const { apiParams: techniciansQuery } = useApiQuery({
        select: 'all',
        sort: { attribute: TechnicianSortableAttributes.NAME, direction: 'asc' }
    });
    const { data: fetchedData, loading, error } = useGetTechnicians(techniciansQuery);
    const { data: techniciansList } = useDataAccumulator(fetchedData);
    useEffect(() => {
        if (!loading && !error)
            params.onLoad();
    }, [loading, error]);
    const technicianOptions = () => {
        return techniciansList === null || techniciansList === void 0 ? void 0 : techniciansList.map((technician) => (React.createElement("option", { key: `technician-${technician.id}`, value: technician.id }, technician.name)));
    };
    return (React.createElement("div", { className: "qmb-select--x-full form__field" }, React.createElement("select", { className: "tech-select additional-tech", value: params.selectedId, onChange: (e) => {
            const selectedId = Number(e.target.value);
            const selectedTech = techniciansList.find((tech) => tech.id === selectedId);
            params.onChange(selectedTech);
        }, disabled: params.disabled }, React.createElement("option", null, "Unassigned"), techniciansList && technicianOptions()), React.createElement("label", { className: "qmb-label" }, translate('technician', { namespace }))));
}
export default TechnicianDropdown;
