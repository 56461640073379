import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TextArea } from '@progress/kendo-react-inputs';
import I18n, { translate } from '@utils/i18n';
import { deficiencyPath } from 'routes';
import api from '@utils/axios';
import ColumnMenuContext from './ColumnMenuContext';

const namespace = 'features.deficiencies_index.resolve_modal';

function ResolveModal({ toggleDialog, deficiencyId }) {
  const { resolutionStatusesForSelect, afterSubmit } = useContext(ColumnMenuContext);
  const defaultResolutionStatus = resolutionStatusesForSelect[0];

  const [resolutionStatus, setResolutionStatus] = useState(defaultResolutionStatus);
  const [notes, setNotes] = useState('');

  const clearForm = () => {
    toggleDialog();
    setResolutionStatus(defaultResolutionStatus);
    setNotes('');
  };

  const onDropDownChange = useCallback(
    (setState) => (event) => {
      setState(event.target.value);
    },
    []
  );

  const onSubmit = () => {
    api
      .put(deficiencyPath({ id: deficiencyId, format: 'json', _options: true }), {
        deficiency: {
          is_resolved: true,
          deficiency_resolution_status_id: resolutionStatus.value,
          resolution_notes: notes,
          date_resolved: new Date().toLocaleDateString('en-US')
        }
      })
      .then(() => afterSubmit());
  };

  return (
    <Dialog title={translate('title', { namespace })} className="qmb-dialog" onClose={toggleDialog}>
      <label htmlFor="resolutionStatus">{translate('resolution_status', { namespace })}</label>
      <DropDownList
        name="resolutionStatus"
        id="resolutionStatus"
        textField="label"
        dataItemKey="value"
        data={resolutionStatusesForSelect}
        value={resolutionStatus}
        onChange={onDropDownChange(setResolutionStatus)}
        size="large"
      />
      <br />
      <br />
      <label htmlFor="resolutionStatus">{translate('resolution_notes', { namespace })}</label>
      <TextArea
        defaultValue=""
        rows={8}
        value={notes}
        onChange={(e) => {
          setNotes(e.value);
        }}
      />

      <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
        <div className="mt-3">
          <button type="button" className="qmb-button" onClick={clearForm}>
            {I18n.t('generic.cancel')}
          </button>
          <button onClick={onSubmit} type="button" className="qmb-button--submit">
            {I18n.t('generic.update')}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

ResolveModal.propTypes = {
  deficiencyId: PropTypes.number.isRequired,
  toggleDialog: PropTypes.func.isRequired
};

export default ResolveModal;
